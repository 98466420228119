import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-sentinel-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1977 haunted house movie, The Sentinel"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 05</h1>
                    <h2>The Sentinel</h2>
                    <h3>April 17, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-sentinel">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:02.248)
                            All right. You&#39;re listening to the Brothers Grimm Podcast presented by the Cinema Suicide Film Society on Substack. If you wanna keep up with us between episodes, you can also find us on Twitter at GrimbrosPod. And if you like what you hear, you can subscribe to us wherever you get your podcasts. Also, you&#39;d be doing us a favor by leaving us a five-star review on Apple Podcasts. I just wanna get all that out of the way right at the top of the show. I&#39;m Brian White, editor in grief of Cinema Suicide, and I&#39;m joined by my co-host</p>
                            <p>Bryan White (00:32.108)
                            like Dave, how you doing? No, all right, all right. This is this coming in hot. This is exciting. We got a good movie to talk about tonight. So we practically grew up in neighborhood video stores and steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and Dave. So before we get rolling, I&#39;m going to give you a taste of what we what we&#39;re talking about today. We&#39;ll give you just give you a little taste.</p>
                            <p>Queen Of The Lizard People (00:33.69)
                            Whoo! Pretty good. Pretty... Pretty... coming in hot.</p>
                            <p>Bryan White (01:23.109)
                            He&#39;s blind.</p>
                            <p>Bryan White (02:06.048)
                            Thanks for watching!</p>
                            <p>Bryan White (02:10.348)
                            Thanks for watching!</p>
                            <p>Queen Of The Lizard People (02:11.95)
                            I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m not sure if I&#39;m going to be able to do this. I&#39;m</p>
                            <p>Bryan White (03:03.573)
                            you</p>
                            <p>Queen Of The Lizard People (03:05.616)
                            you</p>
                            <p>Bryan White (03:32.348)
                            you</p>
                            <p>Bryan White (03:44.868)
                            The Sentinel.</p>
                            <p>Queen Of The Lizard People (03:44.99)
                            That preview, that makes it look way better than it actually is.</p>
                            <p>Bryan White (03:48.088)
                            That trailer may as well just show you the entire movie. Like it&#39;s got all of the like major plot beats. It&#39;s got the entire cast in it. Like it basically shows you start to finish what this movie is exactly about. It leaves nothing to the imagination. So, you know, it&#39;s a good trailer, but fucking Christ, like slow it down guys. Keep them hungry.</p>
                            <p>Queen Of The Lizard People (04:08.493)
                            This is the biggest cast that never speaks.</p>
                            <p>Bryan White (04:11.668)
                            It&#39;s wild. I mean, like that that line Christopher Walken is in that trailer, and that is basically the only line of. Yeah, yeah. He&#39;s got a little bit, but like you will get to it. I got notes on walking. But this movie is just like a parade of of of like cameos. Like most of the people that they sort of list is like major stars in the movie. They&#39;re in it for a few minutes. But like, yeah, it&#39;s it&#39;s mostly it&#39;s mostly Chris</p>
                            <p>Queen Of The Lizard People (04:18.51)
                            That&#39;s the only line he says.</p>
                            <p>Bryan White (04:41.951)
                            than Christina Raines.</p>
                            <p>Queen Of The Lizard People (04:43.83)
                            This is from that era where even the knockoffs had a kind of gravitas to them. Like even knockoffs are well made because this movie.</p>
                            <p>Bryan White (04:50.048)
                            Yeah, yeah. This was like also this was like the this was like the era of Battle of the Network stars. And so like, you know, everything was like a special that brought together all of the major stars from like ABC&#39;s like Thursday night lineup and stuff. It&#39;s really crazy.</p>
                            <p>Queen Of The Lizard People (04:58.271)
                            Oh yeah.</p>
                            <p>Queen Of The Lizard People (05:07.65)
                            Well, I had, I was certain in my memory, Christina Reigns was not in this. It was actually Kate Jackson. Like I remembered it being Kate Jackson. And I was like, wait, that&#39;s like, that&#39;s like knockoff Kate Jackson.</p>
                            <p>Bryan White (05:17.309)
                            That&#39;s weird.</p>
                            <p>Bryan White (05:19.848)
                            Because Kate Jackson was Michael Winner&#39;s original choice for that part.</p>
                            <p>Queen Of The Lizard People (05:27.55)
                            So he actually did go with knockoff Kate Jackson. And then he treated her like crap the entire time.</p>
                            <p>Bryan White (05:29.908)
                            He did go with knockoff Kate Jackson because. Oh, my God. Yeah. So a warning before we get rolling, if you&#39;re a new listener, if you&#39;re coming back, you know how we do. If you&#39;re a new listener, we&#39;re basically talking about this movie from beginning to end. So spoilers to follow. Pause it. Go watch the movie. It&#39;s available. There&#39;s a really great blu ray by Scream Factory. That&#39;s that&#39;s that&#39;s worth your worth your dollars. So, you know, just be warned. We&#39;re going to basically tell you how this movie ends.</p>
                            <p>Queen Of The Lizard People (05:40.194)
                            you</p>
                            <p>Bryan White (05:59.848)
                            some facts.</p>
                            <p>Queen Of The Lizard People (06:00.81)
                            But wait, before you even get into it, I&#39;m gonna tell you, because you asked me a question earlier, well, you raised a question earlier. This movie is basically, what if Rosemary&#39;s baby was also the omen? And then like a little bit of burnt offerings.</p>
                            <p>Bryan White (06:06.632)
                            Yeah.</p>
                            <p>Bryan White (06:13.968)
                            Yeah, and and yeah, and like a police procedure along top of it.</p>
                            <p>Queen Of The Lizard People (06:19.25)
                            Yeah, and that will explain a lot of the shit that does not get explained in this movie.</p>
                            <p>Bryan White (06:23.388)
                            Yeah, yeah. So yeah, okay. So there was also a thing like for some reason in my memory, and maybe it&#39;s just because I have not seen this movie in a really long time, but I seem to remember there being this like conspiracy subplot where Chris Sarandon was like a Satanist or something like that. That&#39;s gotta be it. That&#39;s gotta be it. So</p>
                            <p>Queen Of The Lizard People (06:43.87)
                            because you&#39;re remembering Rosemary&#39;s baby.</p>
                            <p>Bryan White (06:53.248)
                            1977, we&#39;ve got The Hills Have Eyes, Rabid, Suspiria, Shockwaves, Satan&#39;s Cheerleaders, and The Exorcist 2. Yeah, yeah, and definitely this was the, this was kind of like, this was the peak of that time when like every major studio was hungry for its own exorcist. So like there were a million of these movies that if they didn&#39;t just do another possession</p>
                            <p>Queen Of The Lizard People (07:04.87)
                            Electric Boogaloo.</p>
                            <p>Queen Of The Lizard People (07:15.516)
                            you</p>
                            <p>Queen Of The Lizard People (07:23.65)
                            you</p>
                            <p>Bryan White (07:24.068)
                            they did something like the Yeoman or they wanted to do they wanted to cap capitalize on Rosemary&#39;s Baby which is really what this this is really more of a play on Rosemary&#39;s Baby than the Exorcist I&#39;d say so</p>
                            <p>Queen Of The Lizard People (07:35.51)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (07:39.27)
                            Yeah, this is, it&#39;s Rosemary&#39;s Baby and the Omen.</p>
                            <p>Bryan White (07:42.348)
                            Yeah. So.</p>
                            <p>Queen Of The Lizard People (07:43.95)
                            Like right down to the plot devices, the cinematography, everything. It&#39;s both of those movies.</p>
                            <p>Bryan White (07:49.508)
                            Oh, yeah, the hotness. So this is directed by Michael Winter, who worked with Charles Bronson a bit. He&#39;s probably best known for Death Wish, which is a movie that I like a great deal. But he also came back to Death Wish during the canon era, and he directed Death Wish 3 for some fucking reason. Have you ever seen Death?</p>
                            <p>Queen Of The Lizard People (08:08.53)
                            which is arguably one of the worst movies ever made.</p>
                            <p>Bryan White (08:11.728)
                            It is, I love it. I fucking love Deathwish 3. It is one of the craziest, weirdest. Yeah, yeah. So, oh yeah. And she is, basically she&#39;s raped by the gang that&#39;s sort of occupying the Bronx. And she suffers a broken arm and then dies from a broken arm in the hospital. One of the weirdest things about it. This is also the movie where</p>
                            <p>Queen Of The Lizard People (08:17.51)
                            Isn&#39;t that that&#39;s the one with Deanna Troy, right?</p>
                            <p>Bryan White (08:41.988)
                            fights back at the end and there&#39;s like a bunch of bikers riding around like terrorizing them and they like a bunch of the neighbors pull a chain up to clothesline the bikers and then they run out into the street and shoot them where they lay and then from off-screen a bunch of children run into the scene and jump for joy hilarious so he&#39;s made a it&#39;s it was actually shot in Ireland</p>
                            <p>Queen Of The Lizard People (09:02.123)
                            Yes. It&#39;s very it&#39;s very Italian.</p>
                            <p>Queen Of The Lizard People (09:07.914)
                            tomato tomato.</p>
                            <p>Bryan White (09:11.728)
                            also a gigantic piece of shit. He is a sex pest. And when the whole me me too thing flared up, he ended up named by several women who were really probably only a handful of the actual like victim pool, like he probably did this with a lot of people. Notably after he died Marina Sirtis from Star Trek, she plays Troy. She&#39;s like we said, she&#39;s in Death Wish three</p>
                            <p>Queen Of The Lizard People (09:14.016)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (09:35.716)
                            you</p>
                            <p>Bryan White (09:42.048)
                            he rots in hell for all eternity. So great guy. It&#39;s not clear how she was treated on set, but Christina Reign says that she has never actually seen this movie because of the way that she was treated by Winner.</p>
                            <p>Queen Of The Lizard People (09:46.776)
                            you</p>
                            <p>Queen Of The Lizard People (09:57.455)
                            This is she basically stopped acting because of this movie. She did a bunch of TV.</p>
                            <p>Bryan White (10:00.928)
                            Yeah, well, she did a lot. She did a lot of television after this, but I honestly would not, wouldn&#39;t blame her because based on everything that I&#39;ve read about this production, like literally everybody hated working on this movie because of Winter. Chris Sarandon is actually on record saying that he almost quit acting because of it. So great director, garbage person, fuck this guy. So the movie stars, basically everybody you heard in the trailer.</p>
                            <p>Bryan White (10:30.868)
                            primarily it&#39;s Chris Sarandon and Christina Reigns with Burgess Meredith, Beverly D&#39;Angelo. This is her first movie, but she&#39;s been kind of like busy in entertainment for a while. Like she&#39;s known. She was a stage actress. She was a musician. Like a dancer and stuff like she&#39;s like a really sort of multi talented. There&#39;s also John Carradine, Martin</p>
                            <p>Queen Of The Lizard People (10:44.07)
                            She was a stage actress, I think.</p>
                            <p>Bryan White (11:02.288)
                            But this movie also has a bunch of people in small roles who go on to be a big deal. So as we mentioned, Christopher Walken plays a cop who gets a few lines, but he&#39;s actually in a significant portion of like about a third of the movie, but he just kind of like lurks and hovers around. He did. Yeah, they like cut to him and he sort of like puts a little puts a little stank on whatever whatever sort of burn the cop delivers, but he rarely really does not speak.</p>
                            <p>Queen Of The Lizard People (11:15.67)
                            Yeah, he&#39;s just, he looks smug most of the movie. That&#39;s his role.</p>
                            <p>Bryan White (11:30.848)
                            commercial director and then a wild Jeff Goldblum appears in a part that&#39;s actually I wouldn&#39;t say I it&#39;s small compared to everybody else but he&#39;s in it a lot more than I would say everybody else he&#39;s got a lot of spoken lines.</p>
                            <p>Queen Of The Lizard People (11:43.49)
                            Did they make Death Wish right before this?</p>
                            <p>Bryan White (11:45.988)
                            In 74 they made Death Wish and he&#39;s one of the like rapist gang bangers, so he&#39;s like a Michael Winner guy. But I know. But the ensemble approach to casting was Universal&#39;s decision based on the success of Airport.</p>
                            <p>Queen Of The Lizard People (11:54.15)
                            regrettably.</p>
                            <p>Bryan White (12:03.448)
                            Great movie. So, some notes about this before we really get into it. This was based on a 1974 novel by Jeff Konvitz, who I think is also a producer on the movie.</p>
                            <p>Queen Of The Lizard People (12:12.49)
                            He is, do you know why he wrote it? So he, he was a lawyer actually, he&#39;s not a writer. And he worked for, I think one of the, either like one of the management companies or something. And he kept getting beat to like every movie, like every time someone, cause this is when all the studios are just buying up everything. Like every hot new novel that comes out, it&#39;s getting bought almost immediately before it&#39;s even done. And so he, because people keep beating him to the movies, he decides he&#39;s just gonna write his own.</p>
                            <p>Bryan White (12:14.99)
                            No.</p>
                            <p>Bryan White (12:18.932)
                            Yeah.</p>
                            <p>Bryan White (12:31.99)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (12:42.65)
                            So he does. And some company was like one of the publishers was like, we&#39;ll buy the paperback rights to that. And he was like, OK, fine.</p>
                            <p>Bryan White (12:51.488)
                            But he really just wanted the option. Yeah.</p>
                            <p>Queen Of The Lizard People (12:53.07)
                            Yeah, it&#39;s, who else did that? Frank DiFelitta, who wrote The Entity, he also would do shit like that. Yeah.</p>
                            <p>Bryan White (12:58.468)
                            No shit. No kidding. That&#39;s wild. He also wrote a sequel to this. It&#39;s called The Guardian. And I think I got to read both of these. Even though I guess from what I&#39;ve read, I read the synopsis of his novel and the movie seems to follow it pretty closely. But I guess some of the complaints that that Convitz had about it is that there&#39;s a lot of development into the Allison character that just didn&#39;t make it into the movie.</p>
                            <p>Queen Of The Lizard People (13:25.419)
                            That&#39;s pretty evident.</p>
                            <p>Bryan White (13:26.988)
                            Yeah, yeah, she&#39;s, she&#39;s for like a central character. She&#39;s remarkably undercooked. Like there&#39;s.</p>
                            <p>Queen Of The Lizard People (13:32.19)
                            Yeah, here&#39;s a character with no interiority at all and who seems completely incapable. That&#39;s how you know this was written by a man because she is basically just a hysterical woman. That&#39;s it.</p>
                            <p>Bryan White (13:42.968)
                            Yeah, yeah, I would like I mean, I bet you it&#39;s not that much kinder to her in the novel, but I&#39;d be interested to sort of see it and then also to sort of see what happens after this because the movie ends on a on a pretty solid conclusion. Also, for the music, John Williams was originally meant to score the film, but he got roped into a small project called Star Wars.</p>
                            <p>Queen Of The Lizard People (14:08.73)
                            Never heard of it.</p>
                            <p>Bryan White (14:11.091)
                            No, I hear it&#39;s okay. So starting the movie, we open in Italy.</p>
                            <p>Queen Of The Lizard People (14:18.71)
                            Which is basically the opening from The Omen.</p>
                            <p>Bryan White (14:21.948)
                            Yeah, yeah, yeah, okay, yeah. So there&#39;s a secret order of Catholic priests who meet. And it&#39;s not really clear what they&#39;re doing, but it&#39;s just like, it&#39;s a little room, it&#39;s a handful of guys, and they all have this kind of prayer and a chant, which is basically like lines from Paradise Lost. But I don&#39;t know what it is. These movies from this period,</p>
                            <p>Bryan White (14:52.168)
                            to this. And this like like this like basically all of them like the Catholic Church must have been sponsoring these or something because it&#39;s all of these these 70s Satan movies. They&#39;re not just they&#39;re not just Christian.</p>
                            <p>Queen Of The Lizard People (14:53.25)
                            Mm-hmm.</p>
                            <p>Queen Of The Lizard People (15:07.151)
                            Well, you&#39;re coming, this is coming in at like, what, less than 20 years into Vatican II, basically. So, I don&#39;t know if they&#39;re trying to like, make it look cool or whatever, but this movie has a really weird, I mean, they all, all these kind of 70s, Satan things, have a really moralistic bent to them. And this one leans really hard into it, because how this film conceptualizes deviance is so</p>
                            <p>Bryan White (15:13.509)
                            Yeah, yeah.</p>
                            <p>Bryan White (15:28.272)
                            It&#39;s yet.</p>
                            <p>Queen Of The Lizard People (15:36.131)
                            but also so straight white man that it&#39;s like, well, I guess everything is evil then.</p>
                            <p>Bryan White (15:39.29)
                            Yeah.</p>
                            <p>Bryan White (15:42.148)
                            Yeah, well, that&#39;s the thing is like, that&#39;s what I think. I think this movie is up. Out of all of them, I would argue that this movie is the most Catholic horror movie of them all, because like, you know, Protestantism, evangelicalism, like they all have their sort of horror movies. But the way that they handle like evil is much more nebulous. And like, kind of this is really like it&#39;s high church. And this shit hits me right in the sweet spot,</p>
                            <p>Queen Of The Lizard People (16:03.81)
                            Yeah, this is old school evil.</p>
                            <p>Bryan White (16:11.868)
                            why I love it so much. Like, I love that sort of like high church and like religious conspiracy thing. Like, I think it makes for such a fucking cool secret history of the world sort of thing.</p>
                            <p>Queen Of The Lizard People (16:22.47)
                            There&#39;s so much ceremony to it all and that really lends itself to the kind of melodrama of like film, but this type of film in particular.</p>
                            <p>Bryan White (16:30.908)
                            Right, because like even like over here, like Catholicism in America is a really sort of watered down version of the sort of original ecclesiastical because it&#39;s, there&#39;s, it&#39;s a very spooky religion. Like there&#39;s a ton of like ceremony and ritual to it. And it involves a lot of stuff like fucking relics and like everybody, like every station within the church has like a different uniform. Like it&#39;s really crazy.</p>
                            <p>Bryan White (17:00.928)
                            stuff that happens, like even the stuff that continues in Spain and France is like really, really tuned into this sort of thing. So it&#39;s all very spooky. It&#39;s perfect for a horror movie. But we kind of get this out of the way like this, this scene just sort of like establishes it&#39;s a complete left field establishing shot, but it just sort of lets you know that there&#39;s an undercurrent there&#39;s some there&#39;s there&#39;s people working below the below the surface here. But we immediately cut to this is as the credits roll.</p>
                            <p>Queen Of The Lizard People (17:26.65)
                            you</p>
                            <p>Bryan White (17:31.289)
                            We cut to New York to a montage it quickly brings us up to speed on our main characters we get</p>
                            <p>Queen Of The Lizard People (17:36.81)
                            First of all, this is a big departure in terms of the New York we get in Maniac and the New York in this like the contrast is</p>
                            <p>Bryan White (17:43.448)
                            I know this movie works the hell out of out of Central Park and like up uptown so like Maniac is all lower Manhattan and like Brooklyn and Queens and this is all like all like the neighborhoods around Central Park like I don&#39;t think this movie goes below 59th street at all it&#39;s crazy except for when they go to Brooklyn because</p>
                            <p>Queen Of The Lizard People (18:02.39)
                            except for when they go to her Brooklyn apartment. And she&#39;s like, here&#39;s your beautiful apartment next to the highway.</p>
                            <p>Bryan White (18:08.529)
                            It&#39;s got a beautiful view of the city though and the piers. But so, yeah, so we this is this is how they introduce us to her. We get we get Alison Parker, who is a busy model around New York. She.</p>
                            <p>Queen Of The Lizard People (18:12.392)
                            Okay.</p>
                            <p>Queen Of The Lizard People (18:23.49)
                            You know what this whole montage reminds me of? That the date scene from Don&#39;t Panic.</p>
                            <p>Bryan White (18:27.528)
                            from don&#39;t panic. Yeah. Or God, what is it?</p>
                            <p>Queen Of The Lizard People (18:32.55)
                            Where it&#39;s like, here&#39;s young, sexy people having fun.</p>
                            <p>Bryan White (18:35.528)
                            Yeah, and they have a fucking blast. Like they really like they really, really spent a lot of time in the park. Her boyfriend is Michael Lerner, who&#39;s played by Sarandon, who? Good God, that dude is awesome. I I love Chris Sarandon. And honestly, in this movie in particular, his mustache deserves a credit of its own. It&#39;s it&#39;s really like a central part to it, part to his character.</p>
                            <p>Queen Of The Lizard People (18:48.35)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (18:54.294)
                            Oh yeah.</p>
                            <p>Queen Of The Lizard People (18:58.551)
                            It&#39;s between his mustache and Ava Gardner. They&#39;re carrying this movie.</p>
                            <p>Bryan White (19:04.149)
                            So his part was originally offered to Martin Sheen. But then this just blew my mind when I read this. The studio rejected him. Apparently everybody in the crew was like, Martin Sheen&#39;s the guy, but Universal Studios rejected him thinking that he&#39;d done too much television, which is wild because Christina Reigns had worked almost exclusively in television and hadn&#39;t done much film. So.</p>
                            <p>Queen Of The Lizard People (19:27.75)
                            Well, and if he&#39;d originally wanted Kate Jackson, that&#39;s all she&#39;d done. She was on Charlie&#39;s Angels and then in like a shitload of like TV movies, what&#39;s his face? Spelling, Aaron Spelling movies.</p>
                            <p>Bryan White (19:30.568)
                            Also, yeah, she was Charlie&#39;s Angels, right?</p>
                            <p>Bryan White (19:41.508)
                            Oh, yeah. Yeah. But yeah, so it&#39;s just it&#39;s this is just like, it&#39;s a really sort of like economic way to sort of introduce the characters because they don&#39;t have to say anything. There&#39;s no exposition. You just like here&#39;s our main actress. She&#39;s a model. She&#39;s on TV. Everybody everybody kind of knows her. She&#39;s a familiar face. Also, right before it sort of like cuts into the main to the main part of the story. Did you notice when they do that, when she&#39;s on the set of the commercial</p>
                            <p>Bryan White (20:11.911)
                            it&#39;s in reverse? Yeah.</p>
                            <p>Queen Of The Lizard People (20:13.676)
                            Is it? I did notice that it&#39;s a very odd, it had a very odd look to it, like it looked very stilted.</p>
                            <p>Bryan White (20:20.749)
                            Yeah, because they&#39;re also they&#39;re sitting in like tall grass. And so they&#39;re they&#39;re like surrounded by it. But yeah, I could not I could not help but notice that. I wonder like. We haven&#39;t really gotten to the meat yet, so there&#39;s nothing really spooky happening. So is it like?</p>
                            <p>Queen Of The Lizard People (20:24.41)
                            Oh yeah, here&#39;s...</p>
                            <p>Queen Of The Lizard People (20:36.39)
                            I do love that line like later on Eva Gardner says, she asked her like, what do you do? And she says something tells her the shampoo commercial she&#39;s in and Eva Gardner goes, shaking your hair in a field must be glamorous profession.</p>
                            <p>Bryan White (20:47.269)
                            Ha ha!</p>
                            <p>Bryan White (20:50.448)
                            Yeah, yeah. But, yeah, so like, once we get into it, when we find out, this is a couple, obviously, we&#39;ve established that, but they&#39;re trying to find, they&#39;re both independently trying to find a place to live in New York. He wants them to move into a place together. They&#39;ve been dating for a couple of years, she is hell bent on finding a place of her own. And she sort of</p>
                            <p>Queen Of The Lizard People (21:14.69)
                            Now, but here&#39;s the weird thing about this movie. This goes back to the way this character is written. If she spends the first third of this movie talking about, you know, how she needs to be independent and how it&#39;s so important that she establishes this and she can take care of herself. And yet she never does a single thing in this movie for herself. Everybody else solves her problems for her throughout the whole movie, except for finding an apartment. But even that&#39;s rigged because of the Satanists.</p>
                            <p>Bryan White (21:37.128)
                            Yeah. Well, this. Well, well, well, here&#39;s the thing, because like, a little further a little further on, we&#39;ll kind of find out like why she&#39;s so set on doing this. But oh, fuck, I just completely lost my train of thought on that.</p>
                            <p>Bryan White (21:59.068)
                            She... What? She could have just put... What?</p>
                            <p>Queen Of The Lizard People (21:59.85)
                            Is it because of the affair? Because they never actually say, they never really come out and say it, but she was having an affair with Chris Sarandon, and then his wife kills herself or something? And then she tries to kill herself after his wife, it doesn&#39;t make any sense.</p>
                            <p>Bryan White (22:10.368)
                            Yeah, so that&#39;s the thing that.</p>
                            <p>Bryan White (22:15.888)
                            It&#39;s yeah, it&#39;s explained when the when the cop comes into the picture. But yeah, there&#39;s a lot of like baggage that&#39;s just kind of hinted towards. But also there&#39;s that point, so that sort of meet up with her mother where she&#39;s like, why didn&#39;t you leave him? And her mom&#39;s like, where would I go? And so like that was kind of like her motivation for kind of wanting to wanting to do this. But like the thing, oh, the point that I was trying to come to earlier is this whole movie is about is about Allison being doomed.</p>
                            <p>Bryan White (22:47.649)
                            And so even though she&#39;s like struggling against the tide, sort of trying to find her own way and be independent, everybody ends up kind of like carrying her and doing everything for her because right up to the end, like there&#39;s nothing that she can do to stop this. She is doomed to be the next Sentinel. So like I think it all kind of ties up or I&#39;m just kind of reading a little too deeply into it. But</p>
                            <p>Queen Of The Lizard People (23:10.343)
                            Yeah, I think you&#39;re being a little generous.</p>
                            <p>Bryan White (23:12.528)
                            Yeah, probably. But we find we find them this this struck me really funny. So they throw some numbers at her at both of them for for rents. She finds a place that is $600 a month for rent, which is outrageous to her. However, I adjusted that for inflation. That&#39;s about $3,000 a month. And then the place that he finds is $1,000 a month, which is about $5,000. So fuck</p>
                            <p>Bryan White (23:42.448)
                            third. Yeah.</p>
                            <p>Queen Of The Lizard People (23:43.49)
                            Yes it is. And someone who pays not quite that much, but near that much. Yes it is.</p>
                            <p>Bryan White (23:48.848)
                            Yeah. But the main sort of drama between the two of them at this point is Michael is super into getting married. He keeps talking about it. Like I said, they&#39;ve been together for a couple of years. He brings it up, but she&#39;s constantly resisting to this. I don&#39;t know. I might be at least a little offended by this if I were him. If I&#39;m like, hey, we should get married. She&#39;s like, no.</p>
                            <p>Queen Of The Lizard People (24:18.03)
                            Didn&#39;t the last one kill herself?</p>
                            <p>Bryan White (24:19.632)
                            I know that&#39;s the thing as we&#39;re going to find out she&#39;s dodging a bullet</p>
                            <p>Queen Of The Lizard People (24:23.09)
                            Yeah, he&#39;s kind of shitty. And I think that&#39;s probably why you were like, is there something nefarious going on? It&#39;s partially because he&#39;s doing the John Cassavetes thing from Rosemary&#39;s Baby. He&#39;s making it seem like he&#39;s in the league with Satan, but he&#39;s not, he just sucks.</p>
                            <p>Bryan White (24:29.568)
                            This movie is so paranoid and so…</p>
                            <p>Bryan White (24:41.948)
                            That&#39;s, yeah, like, well, that&#39;s the thing is like, there are times where I&#39;m like, what is he doing? Like, what&#39;s his play here? Because he does seem to be actively gaslighting her, but also he does seem like genuinely concerned for her.</p>
                            <p>Queen Of The Lizard People (24:57.17)
                            Yeah, I think he&#39;s just sort of patronizing and a little misogynistic. Like, that&#39;s just the character, but because of the obvious influences on this movie, it reads as paranoid conspiracy.</p>
                            <p>Bryan White (25:00.39)
                            Yeah, yeah.</p>
                            <p>Bryan White (25:09.888)
                            Yeah, yeah, I mean, but it works because like everybody is doing this in such a way that I&#39;m just like, Oh, what are they up to? Like even Ava Gardner, who&#39;s just like a fucking real estate agent? Yeah. So there&#39;s a there&#39;s a little bit of a beat here where the the main story takes a break and we find out that Allison&#39;s father has died. And she goes down to Baltimore for his funeral. And we find out that she&#39;s been having like headaches.</p>
                            <p>Queen Of The Lizard People (25:12.238)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (25:18.31)
                            Yeah, she&#39;s just an asshole.</p>
                            <p>Bryan White (25:39.688)
                            It&#39;s like a chronic thing. And she also doesn&#39;t want to go to the funeral, which makes perfect sense because what happens next is we get a sort of flashback to her childhood. She&#39;s like a teenager in these scenes. And we get to re- Oh yes. We get to re-experience her childhood trauma of walking in on her bare ass naked father being so... Yeah.</p>
                            <p>Queen Of The Lizard People (25:54.056)
                            I have a lot of questions about this whole scene.</p>
                            <p>Queen Of The Lizard People (26:04.17)
                            Who is 90 years old for some reason?</p>
                            <p>Bryan White (26:09.789)
                            with a couple of women.</p>
                            <p>Queen Of The Lizard People (26:12.05)
                            But here&#39;s the thing, is he? Cause they&#39;re just hanging out in bed eating cake. It&#39;s just gross cause he&#39;s gross. But here&#39;s the deviance of this film is like, what&#39;s gross about this? Their body types are supposedly disgusting because he is kind of old and very kind of rail thin. And the two women that are in bed with him are like larger women and they&#39;re kind of cackling and eating cake. Like there&#39;s nothing, I mean, it&#39;s food sex. So I mean, okay.</p>
                            <p>Bryan White (26:14.668)
                            I well yeah that&#39;s that I mean that&#39;s really what it is because like</p>
                            <p>Bryan White (26:27.99)
                            Yeah.</p>
                            <p>Bryan White (26:31.631)
                            This is saggy.</p>
                            <p>Bryan White (26:35.648)
                            Yeah, they&#39;re a little heavy.</p>
                            <p>Bryan White (26:41.949)
                            Yeah, yeah, but do you like?</p>
                            <p>Queen Of The Lizard People (26:42.81)
                            But like beyond that, like we&#39;re, we&#39;re the, they&#39;re coded as deviant because of the way they look and because what they&#39;re doing is sort of non-normative sex, even though they&#39;re not actually having sex, they&#39;re just eating fucking cake. And you know what? She walked into the room, bitch.</p>
                            <p>Bryan White (26:59.43)
                            That is, she did open the door, didn&#39;t she? Like, she heard.</p>
                            <p>Queen Of The Lizard People (27:01.61)
                            And that is not the last time she&#39;s going to do that.</p>
                            <p>Bryan White (27:04.668)
                            Right. Yeah, the. That&#39;s a that&#39;s a solid. That&#39;s a solid point, because nobody&#39;s actually fucking in that scene. It&#39;s just just this. Yeah, yeah, the setup is just a little it&#39;s just so gross, like it really is just like, you know, but at what this is. This is also the first one of the first indicators, the thing that kind of comes up throughout the movie is at one point, like he jumps up and like kind of grabs her</p>
                            <p>Queen Of The Lizard People (27:13.65)
                            I mean, at best, just be like, hey, lock the door next time.</p>
                            <p>Bryan White (27:34.548)
                            hits her and he&#39;s like, he grabs the crucifix that she&#39;s wearing. He&#39;s like, what is this shit? Get rid of this. He like yanks it off of her arm and she runs away. She goes to the bathroom. And she slits her wrists. And I probably would do that too, if I had walked in on that scene, cause that was like some Fellini shit.</p>
                            <p>Queen Of The Lizard People (27:43.55)
                            She immediately goes to kill herself.</p>
                            <p>Queen Of The Lizard People (27:53.41)
                            just be like, Oh my God, I&#39;m so sorry. Maybe lock the door next time. I don&#39;t know. Like</p>
                            <p>Bryan White (27:57.269)
                            Right. I put a fucking sock on the door. But yes, so this is like this is the setup. So we&#39;re just kind of filling out her past a little bit. You still live in with this. Also, they want to sort of put a bead on the father because he comes back into the story later on.</p>
                            <p>Queen Of The Lizard People (28:14.75)
                            But I will say that whole scene, the flashback, the way they do the flashback is actually really cool because if they did it now, you know, it would all be CGI. It wouldn&#39;t be much of anything, but the way that they move the flashback scene, she moves through it as though they&#39;re both there in the shot together. Yeah. Yeah, this is a competently made film.</p>
                            <p>Bryan White (28:19.85)
                            It is.</p>
                            <p>Bryan White (28:24.769)
                            Yeah.</p>
                            <p>Bryan White (28:31.068)
                            It&#39;s very disorienting. I like it. There are like some stylistic. There&#39;s a ton of stylistic moves in this movie. Yeah, but I really I really like like that&#39;s that&#39;s one of the easily one of the first ones. So this is this is where we go back and now she&#39;s meeting. She&#39;s meeting Eva Gardner. Yeah, because she goes back home and Michael&#39;s like every real estate agent in the city is calling you. And so we meet up with Eva Gardner, who</p>
                            <p>Queen Of The Lizard People (28:51.45)
                            So good. Eva Gardner, who was a realtor with a drag queen&#39;s ruthlessness. Every comment is a cutting remark. She says, oh, you&#39;re from Baltimore. How nice.</p>
                            <p>Bryan White (29:04.069)
                            is I know and like sh-</p>
                            <p>Bryan White (29:08.008)
                            It&#39;s so crazy.</p>
                            <p>Bryan White (29:15.21)
                            I know. New Yorkers are just are just obsessed with fame and sex. The is she British or is she just doing like a mid-Atlantic thing? Okay.</p>
                            <p>Queen Of The Lizard People (29:15.794)
                            Damn.</p>
                            <p>Queen Of The Lizard People (29:24.63)
                            No, no, she&#39;s doing Mid-Atlantic. Because she&#39;s, you know, she&#39;s an actress in the 40s and 50s, that&#39;s what they all did. Same thing with Burgess Meredith, he&#39;s gonna do it.</p>
                            <p>Bryan White (29:29.528)
                            Yeah, I love. Yeah, her affect is so fucking funny. And she&#39;s</p>
                            <p>Queen Of The Lizard People (29:35.33)
                            And she&#39;s coming off of Earthquake, which I think they just did Earthquake right before this. And Earthquake, she plays a very similar character who&#39;s just this shady drunk bitch the whole movie. She is awesome.</p>
                            <p>Bryan White (29:39.969)
                            Yeah.</p>
                            <p>Bryan White (29:48.948)
                            They must, and also they must have had her for like one day on set, because she is wearing the same outfit in every scene. She&#39;s got that like white dress and that hat with like the wide brim.</p>
                            <p>Queen Of The Lizard People (30:01.21)
                            Yeah, she&#39;s even dressed like it&#39;s the 50s.</p>
                            <p>Bryan White (30:03.428)
                            And it&#39;s fucking nuts because she&#39;s...</p>
                            <p>Bryan White (30:08.428)
                            It&#39;s and I suppose that they did this on purpose to sort of like make you be like, well, what&#39;s her role in all this? Because she&#39;s being wicked spooky throughout all of this. So her whole thing is she takes her over to the brownstone as we&#39;re going to as we&#39;re going to find out. This is like sort of the central location for the whole movie. So some facts about this, the haunted brownstone of the movie was used for both exteriors and interiors, which is unusual because usually they don&#39;t do that. They&#39;ll they&#39;ll go out, they&#39;ll shoot an exterior and they&#39;ll go to like a sound</p>
                            <p>Bryan White (30:38.428)
                            control over the environment. Cast and crew reported all sorts of weird shit happening inside, like it was actually haunted. On top of that, the apartment that Reigns was given to be used as a dressing room was being rented also by a priest. It&#39;s located, it&#39;s actually located in Brooklyn Heights. I think it&#39;s actually at the address that they give in the movie. So you can go there. It still stands to this day. That building that they show at the end after the old one is ripped down. That&#39;s a</p>
                            <p>Queen Of The Lizard People (30:42.75)
                            I&#39;m sorry, I&#39;m sorry. I&#39;m sorry, I&#39;m sorry. I&#39;m sorry, I&#39;m sorry. I&#39;m sorry, I&#39;m sorry.</p>
                            <p>Queen Of The Lizard People (30:55.616)
                            you</p>
                            <p>Bryan White (31:10.73)
                            So yeah, back to this. Gardener&#39;s being super spooky. Like everything about her body language, the way that she sort of delivers everything, like it&#39;s like you&#39;re reading it like a normal person. I&#39;m reading it like a paranoid maniac because everything she says has this weird undertone tone to it. Like she knows the deal with the place, but like she never really gives you</p>
                            <p>Queen Of The Lizard People (31:30.41)
                            Well, no, you&#39;re right. Like everybody in this movie is playing it like there is some sort of satanic conspiracy going on. The problem is, is that they have not done the work to explain any of that. So you&#39;re just sort of like, I don&#39;t get it. Why is she being such a weird asshole?</p>
                            <p>Bryan White (31:40.411)
                            Yeah.</p>
                            <p>Bryan White (31:43.648)
                            Right. Because like Christina Reigns walks around this place, like looking at and Ardner just is like they cut to like all these closeups of her with this like sinister look on her face or like shooting her a side eye with like a smirk. And even the music in that scene is very tense. It&#39;s yeah, it really works. But at no point do they ever give you really any reason to think that she&#39;s on the nature of it, as we said, or like that, like she&#39;s like a satanic or she&#39;s in on it with the</p>
                            <p>Queen Of The Lizard People (31:59.37)
                            The music in this whole movie is jarring.</p>
                            <p>Bryan White (32:13.928)
                            or anything like that. It&#39;s just like I said, super paranoid movie. They do it really well. So from here we cut to a photo shoot with Goldblum. And apparently all of his movies were, all of his lines in the movie were dubbed. But the guy doing the dub sounds just like him.</p>
                            <p>Queen Of The Lizard People (32:31.918)
                            Jeff Goldblum, who even in the 70s was perfecting that like shirt unbuttoned all the way. He&#39;s the only one who can get away with that. He&#39;s doing it all the way. He does it today.</p>
                            <p>Bryan White (32:38.573)
                            Yeah.</p>
                            <p>Bryan White (32:42.708)
                            Yep, yep. He&#39;s a fabulous man. I love Jeb Goldblum to death. But you don&#39;t really get the full Goldblum. Like, I wonder how long it took him to really sort of fall into that character with the um-uh thing that he does a lot, because now he&#39;s like...</p>
                            <p>Queen Of The Lizard People (32:57.19)
                            he does it in what is it Earth Girls Are Easy? Is that the one he&#39;s in? Yeah, he does it in that. Yeah, because his thing in the fly actually his his like passcode to get into the computer has that weird pause in it.</p>
                            <p>Bryan White (33:01.168)
                            Yeah. He does it in he does it in the fly. Um I think he does it in</p>
                            <p>Bryan White (33:13.388)
                            Yeah, yeah, but yeah, none of that is in this. Like that must have been like just something that he developed on his own, but like, I think that&#39;s like really what he&#39;s like. So who knows? But this is like one of the firsts of Allison&#39;s headaches that we see. Cause this isn&#39;t, it&#39;s not just like a headache. It&#39;s she like suffers like a collapse.</p>
                            <p>Queen Of The Lizard People (33:36.671)
                            Oh, is this at the, this is where they&#39;re at the kind of like that biggest state. This is another Omen moment. This is the moment. This is like basically when they go to the zoo in the Omen. Because for some reason, the animals that they&#39;re using in this shoot at the estate just like freak the fuck out for no discernible reason other than it&#39;s what happens in the Omen.</p>
                            <p>Bryan White (33:39.068)
                            They&#39;re yeah, like all the animals are going crazy.</p>
                            <p>Bryan White (33:45.891)
                            Oh my god, you&#39;re right.</p>
                            <p>Bryan White (33:54.111)
                            Yeah.</p>
                            <p>Bryan White (33:56.448)
                            Right, but in... In the Omen, it&#39;s very ominous. In this one, it&#39;s almost like it&#39;s played for laughs.</p>
                            <p>Queen Of The Lizard People (34:05.61)
                            Oh yeah, because they&#39;ve got like the bumbling animal wrangler who&#39;s like, ah, the peacock&#39;s in the pool now. And it&#39;s like, the fuck does this have to do with anything?</p>
                            <p>Bryan White (34:10.812)
                            Ha!</p>
                            <p>Bryan White (34:14.268)
                            Yeah, also it&#39;s never really established why she has these headaches. Like usually like there&#39;d be a bit of exposition, but like, like a lot, like I feels like this movie feels like it&#39;s missing like whole pieces of story at times. But this is just another thing, it&#39;s happens a bunch. So.</p>
                            <p>Queen Of The Lizard People (34:31.35)
                            and she responds to them as though she has a brain tumor.</p>
                            <p>Bryan White (34:34.928)
                            Yeah, like, I mean, it screams and grabs her temples and like falls to the ground. And yeah, that&#39;s Satan doing that. But we go back to her apartment, the new place she&#39;s moved in, and it&#39;s a furnished apartment. So it&#39;s just this like clutter barn of stuff. But she&#39;s kind of in there walking around.</p>
                            <p>Queen Of The Lizard People (34:39.85)
                            That&#39;s Satan. That&#39;s Satan doing that.</p>
                            <p>Queen Of The Lizard People (34:54.01)
                            Which, if you notice everybody who goes into that apartment, comments on the decor.</p>
                            <p>Bryan White (34:59.188)
                            Yeah, yeah.</p>
                            <p>Queen Of The Lizard People (35:01.39)
                            I think Birch is married at one point, it&#39;s like you have impeccable taste.</p>
                            <p>Bryan White (35:04.068)
                            Yeah, yeah, yeah, because this is the scene where he comes in for the first time. There&#39;s a knock at the door and she opens it and it&#39;s fucking Burgess Meredith.</p>
                            <p>Queen Of The Lizard People (35:11.35)
                            who it should be said also reads as pretty queer. He always kind of did, but he does especially in this movie. He&#39;s a weird little man.</p>
                            <p>Bryan White (35:14.748)
                            I know I was I had to I had to look it up. I was I was like, was he like a bachelor or something like that? Like, you know, nope, nope, just a regular guy. But then again, we also kind of like I remember Denise asked me that one day when we were watching. I can&#39;t remember which Vincent Price movie it was, but she was like, was Vincent Price gay? I&#39;m like, no, that was just like the way that. Yeah, I was like, nope, it&#39;s just a just a man of the stage.</p>
                            <p>Queen Of The Lizard People (35:21.894)
                            Nope.</p>
                            <p>Queen Of The Lizard People (35:36.191)
                            BANG!</p>
                            <p>Queen Of The Lizard People (35:38.65)
                            He kind of was.</p>
                            <p>Bryan White (35:44.969)
                            And it&#39;s just it&#39;s the it&#39;s the campy thing like so many of these guys</p>
                            <p>Queen Of The Lizard People (35:47.89)
                            It is, and you can tell that he&#39;s having fun doing it. Like you can tell Burgess Meredith is having fun in this role. He probably hated it, but he&#39;s doing it.</p>
                            <p>Bryan White (35:56.368)
                            He&#39;s him and Martin Balsam seem to be the only two people in this movie who are enjoying themselves. Cause like Meredith is swinging for the fucking fences, like right off the bat. He comes in and he&#39;s like, hello, you know, he&#39;s really, really big. He&#39;s got a canary on his shoulder and he&#39;s got this cat that is just like, yeah. And Jezebel. Mortimer is from Brazil. But like the cat,</p>
                            <p>Queen Of The Lizard People (36:09.633)
                            Mm-hmm.</p>
                            <p>Queen Of The Lizard People (36:14.27)
                            Mortimer? I believe Mortimer is the canary.</p>
                            <p>Queen Of The Lizard People (36:18.77)
                            Just melts the cat, yep. Yep.</p>
                            <p>Bryan White (36:26.669)
                            and like really pitching a fit in his arm, but he&#39;s like fully committed to just full tilt camp. Like he must have been like, alright, so how do you want me to do this? And they&#39;re just like, I don&#39;t care. And he was like</p>
                            <p>Queen Of The Lizard People (36:40.731)
                            And even he&#39;s so even like, he&#39;s sort of secondary to Ava Gardner. This is why I think there must have been a gay person working on the script somewhere in this movie because he says she again asked like, what do you do? And she says, Oh, I&#39;ve done some television commercials. And he goes, Oh, really? I thought you were an actress. I was like, damn, everybody is coming for her.</p>
                            <p>Bryan White (36:45.348)
                            Thanks for watching!</p>
                            <p>Bryan White (36:49.109)
                            Hahaha</p>
                            <p>Bryan White (36:55.868)
                            I thought you were an actress!</p>
                            <p>Bryan White (37:03.668)
                            It really is no wonder like I mean she is just a fucking target the whole time to everybody like</p>
                            <p>Queen Of The Lizard People (37:09.97)
                            And every character, everyone in this movie has a huge personality except her. She just sort of is there like a fucking wet paper bag.</p>
                            <p>Bryan White (37:14.869)
                            Yeah.</p>
                            <p>Bryan White (37:18.228)
                            She is very, that&#39;s, it&#39;s like really the most disappointing thing because I think that she&#39;s a good actress, and what she does have she plays well but there&#39;s just not much like everybody else even the fucking cops have like more story than she does.</p>
                            <p>Queen Of The Lizard People (37:36.272)
                            Yeah, I mean, John Carradine has more charisma and all he does is sit there.</p>
                            <p>Bryan White (37:41.328)
                            Yeah. Yeah. But, um, so yeah, he just kind of like flits around her apartment, just chewing the scenery. There is hardly any room in the scene for her. And you can actually you can if you watch her in the scene, she&#39;s barely holding it together. Just he&#39;s so fucking funny.</p>
                            <p>Queen Of The Lizard People (38:03.95)
                            And then he leaves a picture of himself, like a framed photograph of himself in the apartment.</p>
                            <p>Bryan White (38:06.208)
                            Yeah, but she. Yeah, but she but she but she doesn&#39;t realize it till after he&#39;s gone. It&#39;s like, where the fuck did he have that thing? Like, it&#39;s too big for a pocket and he had a cat, a very angry cat and a bird. Like that dude is sneaky. But yeah, he leaves a photo of himself holding like a dozen roses. It&#39;s a really crazy picture. But she doesn&#39;t seem all that bothered by it. And so like, yeah, we&#39;ve established that there&#39;s other</p>
                            <p>Queen Of The Lizard People (38:14.55)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (38:26.494)
                            Yup.</p>
                            <p>Bryan White (38:36.428)
                            in the building. Well, well first, yeah we are, but first there&#39;s that scene where they go to like a party at Michael&#39;s friend&#39;s house where he&#39;s like showing vacation photos and there&#39;s like the Arc de Triomphe and he&#39;s like it&#39;s upside out and he&#39;s like I took it on my head.</p>
                            <p>Queen Of The Lizard People (38:37.39)
                            Now we&#39;re going to meet some more of them.</p>
                            <p>Queen Of The Lizard People (38:55.21)
                            Exactly, where again you hear you have when she&#39;s like, your friends are weird and it&#39;s like, well, when you say weird, I think you might mean something else actually.</p>
                            <p>Bryan White (39:02.709)
                            Well, OK, OK, that&#39;s also a good point, because at the beginning, like really right at the beginning, like once they&#39;re doing the sort of apartment hunting scene, Michael is in a place with another real estate agent who doesn&#39;t say it. But a lot of the lines he gives them are like, like, you&#39;re not gay, are you? Like, it&#39;s a lot of stuff like that. You are married, right? It&#39;s yeah. And there&#39;s there&#39;s another line after that. That&#39;s also like he&#39;s really trying to put a fine point on it, like</p>
                            <p>Queen Of The Lizard People (39:20.81)
                            Yeah, he&#39;s like, well you are married, right?</p>
                            <p>Bryan White (39:30.848)
                            this dude is not gay oh uh it&#39;s something about the way that he dresses yeah because he needs we need a lot of yeah we need a lot of closet space so</p>
                            <p>Queen Of The Lizard People (39:35.31)
                            Yes, because he says something about, you know, a full length mirror.</p>
                            <p>Queen Of The Lizard People (39:42.832)
                            Now, so that&#39;s interesting because at the party too, you also get the two of them are like sort of hanging out and it&#39;s very like, here&#39;s normal, heterosexual coupling.</p>
                            <p>Bryan White (39:54.588)
                            Right, and they&#39;re very separated from the rest of them because they&#39;re like not just not just away from everybody they&#39;re like slumped down in like beneath a couch basically while while while a very very gay man shows his his vacation photos to a crowd of people who are just not interested.</p>
                            <p>Queen Of The Lizard People (40:13.15)
                            And that&#39;s going to be a weird contrast from the next scenes or the next couple of scenes actually.</p>
                            <p>Bryan White (40:17.989)
                            Right, right. And it&#39;s like, I don&#39;t even remember. I think this is another scene where he&#39;s just like, why don&#39;t we get married? And she&#39;s like, no, but we cut to the, some other people in the, in the building. And this is one of my favorite scenes in the whole movie because it is so fucking uncanny.</p>
                            <p>Queen Of The Lizard People (40:33.81)
                            But it should be said that she also walks into their apartment without knocking.</p>
                            <p>Bryan White (40:38.668)
                            She just strolls in. So I guess, what do you expect? But yeah, so.</p>
                            <p>Queen Of The Lizard People (40:42.971)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (40:45.15)
                            Well, she wasn&#39;t expecting predatory lesbians, I will tell you that much.</p>
                            <p>Bryan White (40:48.588)
                            That&#39;s oh my God. Yeah. And they&#39;re and they&#39;re they&#39;re they&#39;re particularly weird. Like so this is shit. Is it Sylvia Miles? And Beverly D&#39;Angelo. And so they&#39;re both dressed as though they&#39;re going to dance class or something. They&#39;re wearing like top to bottom leotards and like tights and stuff like that. But Beverly D&#39;Angelo&#39;s character never speaks. She&#39;s like a mute. And Sylvia Miles is</p>
                            <p>Queen Of The Lizard People (40:57.551)
                            Sylvia Miles and.</p>
                            <p>Queen Of The Lizard People (41:17.716)
                            She rarely speaks.</p>
                            <p>Bryan White (41:19.188)
                            Yeah, she plays Gerda who&#39;s like just this very like severe very intense German woman. And she like leaves the two she keeps like stepping away and like leaving Allison and Sandra, I think is her name in the same room. And it&#39;s just like this very kind of like weird just kind of like awkward like you&#39;re stuck in a room with a person you don&#39;t know and like you just have zero ability to make you know small talk with this person.</p>
                            <p>Bryan White (41:48.508)
                            steps away again, and Sandra begins masturbating. And it is it is uncomfortable to watch because like it is just such a strange fucking thing for a person to do.</p>
                            <p>Queen Of The Lizard People (42:04.013)
                            But again, she could just get up and leave, but she sits there.</p>
                            <p>Bryan White (42:06.788)
                            That&#39;s the thing. Ordinary people would be so fucking freaked out. Like this is.</p>
                            <p>Queen Of The Lizard People (42:12.97)
                            Ordinary people wouldn&#39;t walk into a stranger&#39;s apartment and then sit down and you know, these lesbians are nice enough to offer her a drink. And what does she do? She freaks the fuck out.</p>
                            <p>Bryan White (42:17.518)
                            solid point.</p>
                            <p>Bryan White (42:22.545)
                            Yeah.</p>
                            <p>Bryan White (42:24.888)
                            And yeah, and so like she encounters this and this woman goes all the way with it until, oh, the fucking detail that really, really puts me over the edges right after she&#39;s done. She takes her hands and she fucking smells them. Ha ha ha.</p>
                            <p>Queen Of The Lizard People (42:37.85)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (42:42.79)
                            Yeah, that&#39;s rough.</p>
                            <p>Bryan White (42:46.148)
                            Yeah, it&#39;s so intense. But then, yeah, but then Gerda comes back and she resumes her conversation with these people and she asks them, like, what do you do for work or something like that? Or maybe she just says, what do you do? What do you people do? Like, you know, as if asking, like, what&#39;s your job?</p>
                            <p>Queen Of The Lizard People (43:05.56)
                            Oh no, she implies that it is like, what do you do for a living or something?</p>
                            <p>Bryan White (43:09.468)
                            Yeah, and she said she heard her replies, we fondle one another. And then, you know, we caress one another. And that&#39;s what she had like she fully reaches down into into Sanders leotard. And this is when like, apparently, this is the moment when Allison has had enough. She gets up and excuses herself.</p>
                            <p>Queen Of The Lizard People (43:14.051)
                            Only in New York.</p>
                            <p>Queen Of The Lizard People (43:28.21)
                            So again, you&#39;ve got this sort of non-normative sexuality is being presented as menacing and deviant, even though, I mean, and granted, she is sitting there masturbating in the living room in front of a stranger, but like you walked into their house and it&#39;s not like, I&#39;m not saying it&#39;s cool to do that in front of a stranger. I actually think it&#39;s illegal, but still, presenting this like, here are these two kind of odd looking women, because all the other people</p>
                            <p>Bryan White (43:40.448)
                            Yeah.</p>
                            <p>Bryan White (43:49.99)
                            Ha ha!</p>
                            <p>Queen Of The Lizard People (43:58.27)
                            dead serial killers are kind of beautiful people. Like everyone from, you know, Christina Reigns, Chris Sarandon, they&#39;re all really attractive people. Like even Christopher Walken, who is kind of a classically odd looking man is very attractive in this movie. So all the normal people are beautiful and all these evil satanists are very odd looking. And that is sort of connected in some way</p>
                            <p>Bryan White (44:05.63)
                            Yeah... Yeah.</p>
                            <p>Bryan White (44:15.611)
                            Yeah.</p>
                            <p>Bryan White (44:20.492)
                            you</p>
                            <p>Queen Of The Lizard People (44:28.951)
                            And that&#39;s what makes them menacing. And it&#39;s like, this is a very, again, it&#39;s that very kind of Catholic, anything that is outside of kind of procreative sex is a threat.</p>
                            <p>Bryan White (44:41.308)
                            Yeah, yeah. What is it? Oh, so she goes out into the hallway to sort of escape and what is it? Gerda comes out, sort of follows her. She&#39;s like, it&#39;s very rude to eat and run. And she&#39;s like, I didn&#39;t eat, I just had a drink. And then Burgess Meredith kind of comes through and rescues her.</p>
                            <p>Bryan White (45:04.508)
                            But now we we we we cut away again. It&#39;s another scene for another kind of like fainting spell. Like this is just sort of to establish like this is a thing that&#39;s happening to her and it&#39;s starting to like interfere with her work. It&#39;s like breaks through a window. This is the commercial shoot for the wine with Jerry Orbach. And.</p>
                            <p>Queen Of The Lizard People (45:06.494)
                            you</p>
                            <p>Queen Of The Lizard People (45:15.372)
                            Oh, is this when she like crashes through the barrier?</p>
                            <p>Queen Of The Lizard People (45:21.55)
                            All right. OK, maybe not everyone looks attractive. Jerry Orbach just looks like Jerry Orbach, and that&#39;s not a knock on Jerry Orbach. I love him, but you know.</p>
                            <p>Bryan White (45:28.453)
                            Yeah, yeah, yeah, he&#39;s a fine fella.</p>
                            <p>Queen Of The Lizard People (45:34.21)
                            That&#39;s a mustache that&#39;s not working.</p>
                            <p>Bryan White (45:36.388)
                            No, no, because it&#39;s it&#39;s it&#39;s almost like it&#39;s almost like a pencil thin mustache, but it has that kind of thick part like like a like a Hitler toothbrush mustache. It&#39;s not working. Get rid of the get rid of the facial hair Jerry Jerry or buck. But yeah, she has like</p>
                            <p>Queen Of The Lizard People (45:46.192)
                            Yep.</p>
                            <p>Queen Of The Lizard People (45:52.29)
                            It reminds me of like the part when the, the, in the Wizard of Oz, when Dorothy&#39;s telling the story about how she has to, why she has to get home and the guard is like crying and wringing out his mustache. It&#39;s like that where his whole face is saturated. That&#39;s what Jerry Arbok looks like.</p>
                            <p>Bryan White (46:03.248)
                            Ha ha ha!</p>
                            <p>Bryan White (46:07.488)
                            Yeah, yeah, but, um, yeah, she has like the real bad fainting spell where she like crashes through like a window basically and completely falls out and everybody except Orbach is like, oh my god, somebody help her. He&#39;s like, get another woman in her dress and we&#39;ll use somebody else&#39;s hands for close-up. Like, fucking calm down, dude.</p>
                            <p>Queen Of The Lizard People (46:27.69)
                            I mean, they&#39;re on like take 90 because she just refuses to put I mean, this is why she&#39;s not an actress.</p>
                            <p>Bryan White (46:29.95)
                            Yeah, it was awesome.</p>
                            <p>Bryan White (46:34.068)
                            She&#39;s defiantly not showing them the label on the wine bottle. But, yep, so now we go back and she she&#39;s like trying to sort of wind down in her apartment. There&#39;s another knock and it&#39;s it&#39;s Burgess Meredith again. His character&#39;s name is Charles Chazen, by the way. It is. Yeah, it&#39;s very memorable, like like she was a Jack Kirby and Stan Lee. We&#39;re always doing that where everything was an alliteration, because it&#39;s very easy to remember.</p>
                            <p>Queen Of The Lizard People (46:54.39)
                            It&#39;s just a great name.</p>
                            <p>Bryan White (47:05.249)
                            But he comes in and he&#39;s just again like she&#39;s just beat and tired. She&#39;s had a breakdown at work and he is mowing down the scenery. Like he just comes through on fire. He&#39;s like, well, yeah, but he&#39;s like, come, come with me. You know, this will make you feel better. And he like takes her out, blindfolds her before letting her into the apartment. And then they they take the blindfold off and it&#39;s just a room full of people. And the place is stocked with plants, too. Like it&#39;s a like,</p>
                            <p>Queen Of The Lizard People (47:15.291)
                            He&#39;s a lot. He&#39;s very needy.</p>
                            <p>Queen Of The Lizard People (47:32.89)
                            kind of into this apartment that he&#39;s got.</p>
                            <p>Bryan White (47:35.009)
                            It&#39;s a very 70s, like I feel like a lot of like spider plants and like hanging plants was like a big thing in the 70s. Yes. Yeah, but like the whole place is full. It&#39;s a birthday party for the cat, which I love. Yeah, because they have a birthday party for the cat. But like he is again like fucking on fire. Like even if other people had scripted lines, like he would just step on them in this scene. It&#39;s crazy.</p>
                            <p>Queen Of The Lizard People (47:39.031)
                            Yeah, there&#39;s a lot of macrame.</p>
                            <p>Queen Of The Lizard People (47:46.71)
                            That&#39;s how you know they&#39;re Satanists. It&#39;s a birthday party for a cat.</p>
                            <p>Queen Of The Lizard People (48:01.05)
                            And this scene again, this is another example of that, like, look at all these weird body types. Look at all these strange looking people.</p>
                            <p>Bryan White (48:08.868)
                            Yeah, I suppose, though this isn&#39;t quite as, like the rest of the, the rest of the people, because this is like, this scene sort of is like, okay, so here are the other people who live in the apartment building as well. And they&#39;re not as, they&#39;re not as outrageous as, you know, Sylvia Miles and Sandra there. But I suppose like, there is like something about each one of them that&#39;s a little off, you know?</p>
                            <p>Queen Of The Lizard People (48:35.69)
                            Yeah, there&#39;s the twins who are like, there is a good 15 to 20 year age difference between these two women and they look nothing alike.</p>
                            <p>Bryan White (48:38.348)
                            Thanks for watching!</p>
                            <p>Bryan White (48:43.968)
                            Yeah, I mean, again, that might have been on purpose, like just just to call them the twins because like, fuck it, why not? This is a weirdo scene.</p>
                            <p>Queen Of The Lizard People (48:52.63)
                            Yeah, this whole part. So this is the party for the cat, right? And this this essentially kind of devolves into the rape scene from Rosemary&#39;s baby.</p>
                            <p>Bryan White (48:55.168)
                            Yeah, yes.</p>
                            <p>Bryan White (48:58.292)
                            you</p>
                            <p>Bryan White (49:03.268)
                            Yeah, oh yeah. Yeah, except that like that&#39;s the big like third act reveal like the like Hail Satan the year is one. Yeah, but that again. Yeah, I can definitely see that like this is the This is definitely that kind of a kind of a vibe because like every like that scene like everybody&#39;s also like all of the neighbors in that building are also all kind of fucked up and weird to</p>
                            <p>Queen Of The Lizard People (49:28.15)
                            And the party just sort of spins out into this like psychedelic shit show.</p>
                            <p>Bryan White (49:33.988)
                            Yeah, and he, so Meredith puts like a record on it&#39;s polka music and they start dancing and everybody&#39;s having a grand old time. There&#39;s a line that one of the women says he brings out the cake for the cat and he&#39;s and the woman says black and white cat. Yeah, yeah, and I don&#39;t know I don&#39;t know why like there&#39;s a there&#39;s a real there&#39;s</p>
                            <p>Queen Of The Lizard People (49:52.55)
                            Black and white cake for a black and white cat.</p>
                            <p>Bryan White (50:04.149)
                            later.</p>
                            <p>Queen Of The Lizard People (50:05.61)
                            It&#39;s probably why it&#39;s probably so they could do the callback. Because the way she presents the woman is she looks like she&#39;s about 90. And she&#39;s very, you know, she&#39;s very frail and very confused looking. And again, it&#39;s that like, here&#39;s a weird person saying a weird thing so we can later on when this shit hits the fan, call back to it. And it&#39;s it&#39;s sort of memorably menacing.</p>
                            <p>Bryan White (50:08.511)
                            Yeah.</p>
                            <p>Bryan White (50:14.09)
                            Yeah.</p>
                            <p>Bryan White (50:28.688)
                            Yeah, yeah, like maybe that&#39;s it. Because I mean, really that is like this scene. There&#39;s not a whole lot that really makes sense. It doesn&#39;t it doesn&#39;t really amount to much. It&#39;s just establishing this group of people as a bunch of fucking weirdos who have a birthday party for a cat. Cat looks adorable in that party hat, by the way.</p>
                            <p>Queen Of The Lizard People (50:45.31)
                            It reminded me of that cat you used to have like 20s.</p>
                            <p>Bryan White (50:49.208)
                            Yeah, boo, same kind of cat that like that a tuxedo cat. But. So we go back, so the party&#39;s over, she&#39;s down in her place.</p>
                            <p>Bryan White (51:02.288)
                            And she is awoken in the middle of the night. And one of the things that we find out during the party is the apartment directly above hers was like flooded at one point to such a point that like it was just too damaged to to lease ever again. So nobody&#39;s in it. But when she wakes up, there&#39;s definitely like footsteps. And whoever is stomping around up there is doing it in such a way that it&#39;s like shaking the chandelier in her apartment,</p>
                            <p>Bryan White (51:32.149)
                            violently.</p>
                            <p>Queen Of The Lizard People (51:33.35)
                            The part you forgot to mention is that, because it is ties to this, is that Chris Sirhandon has hired like a private investigator, right?</p>
                            <p>Bryan White (51:43.508)
                            Yeah, but that comes later because this they do this twice and then we kind of find out because at this point It&#39;s kind of played for spooks and later on you kind of find out that he&#39;s behind it</p>
                            <p>Queen Of The Lizard People (51:52.37)
                            Because this is the point where this is like the halfway mark where the movie becomes like it takes a turn and becomes a different kind of story.</p>
                            <p>Bryan White (51:59.368)
                            Yeah, and that&#39;s the thing, like this movie, I love this movie. It&#39;s definitely uneven in the pacing. Like. Yeah. A little wobbly for sure. But she she&#39;s so like disturbed by this that she has another meeting with Ava Gardner. And it&#39;s the it&#39;s it&#39;s exactly what you think is going to happen. Like she sits down, she starts talking about all these all the weirdos that that live in the place. And she&#39;s like,</p>
                            <p>Queen Of The Lizard People (52:05.23)
                            Yeah, the first half great. Last half.</p>
                            <p>Bryan White (52:29.348)
                            Those ladies downstairs really carry things a bit too far. All I could think of was, you think? Like one of them beat off in front of you like. But of course, their apply is basically why no one&#39;s lived in that building for years.</p>
                            <p>Queen Of The Lizard People (52:37.19)
                            Yeah, that&#39;s a polite way of putting it, I guess.</p>
                            <p>Queen Of The Lizard People (52:47.69)
                            And this is like, again, Ada Gardner fucking nails it. Like this whole part is she she is giving you kind of exhausted and she&#39;s sort of done with with Christina Reigns bullshit. She&#39;s just like, look, I already got my fucking commission. And now what do you want from me?</p>
                            <p>Bryan White (52:50.652)
                            Oh yeah.</p>
                            <p>Bryan White (53:01.309)
                            She really is.</p>
                            <p>Bryan White (53:04.728)
                            This is not the last time that she hits her up. So like this, yeah, this is the part where it&#39;s like, it&#39;s just like everybody in this movie is gaslighting Allison. And there&#39;s really.</p>
                            <p>Queen Of The Lizard People (53:15.15)
                            but for no particular reason.</p>
                            <p>Bryan White (53:17.268)
                            That was my next thought is there&#39;s really no reason for it unless the church&#39;s hidden power just extends to the New York real estate market. But Ava Gardner&#39;s attitude is so strange and that part earlier, because this isn&#39;t even really the weirdest part. And the part that really kind of threw me is right at the beginning when they&#39;re first touring the place, she says, it&#39;s gonna be 500 a month and Christian, and Alison goes, ah, you know, that&#39;s a little much. And she goes, oh, but 400 is fine. It&#39;s like,</p>
                            <p>Queen Of The Lizard People (53:45.293)
                            Mm hmm. Yeah, she pivots. She doesn&#39;t know she says, I don&#39;t. She&#39;s like, honestly, I don&#39;t think 400 is too much to ask. Like she pivots as though she is gaslighting her, as though she&#39;s like, that&#39;s not what I said. When it&#39;s like, why are you doing that? You could just be like, oh, I&#39;m sorry. No, it&#39;s 400, I guess.</p>
                            <p>Bryan White (53:57.288)
                            It&#39;s yeah, it&#39;s like.</p>
                            <p>Bryan White (54:04.608)
                            Yeah, yeah, and so like the the the</p>
                            <p>Queen Of The Lizard People (54:07.05)
                            But it&#39;s that, what&#39;s her name? She&#39;s also in Rosemary&#39;s Baby. Ruth Gordon, the Ruth Gordon character, who does a lot of that, of like that kind of weird double-speak type of thing. That&#39;s kind of what she&#39;s doing. They&#39;re all just channeling characters from other things. [&quot;Rose Mary&#39;s Baby&quot;]</p>
                            <p>Bryan White (54:19.773)
                            Yeah.</p>
                            <p>Bryan White (54:22.811)
                            Yeah.</p>
                            <p>Bryan White (54:25.648)
                            Okay, so that&#39;s really the answer to my question, is like, why is this movie so paranoid? Why is literally everybody fucking with her? And what the answer really is, is they&#39;re just copying Rosemary&#39;s baby.</p>
                            <p>Queen Of The Lizard People (54:30.057)
                            Yeah.</p>
                            <p>Queen Of The Lizard People (54:37.75)
                            Yeah, they&#39;re trying to, they&#39;re reminding you of another movie that is exponentially better and that everyone has seen.</p>
                            <p>Bryan White (54:44.588)
                            Yeah, because I&#39;m probably going to bring this up a bunch of times in my notes, because like this kept bothering me because like I watched this twice and rather quick succession, like the first time to really kind of take it in. And then the second, like just like how I usually do it, where I like watch it once just to take it in. And then the second time to really take all the notes. And so like a lot of this is all very fresh. And like the second time I watched it, I&#39;m like, why is everybody on her like this? It&#39;s so crazy. Yeah.</p>
                            <p>Queen Of The Lizard People (55:06.25)
                            Yep. It&#39;s also written by a guy who&#39;s not a writer. So there&#39;s not a lot of like, you know, that&#39;s why the pacing is weird and uneven. That&#39;s why there&#39;s not a lot of explanation. That&#39;s why there&#39;s not one, but two exposition dumps at the end of this movie.</p>
                            <p>Bryan White (55:21.348)
                            Oh, my God, it is the clumsiest in the world. But yeah. But OK, so this is where we find out that her boyfriend is like hiring a PI. But this scene comes out of nowhere. He just like leaves his apartment or he leaves his office. And you see him going to. The brownstone. And then we do.</p>
                            <p>Bryan White (55:51.388)
                            another scene where she wakes up at 330 in the morning, the witching hour, and there&#39;s like stomping and banging and stuff, and so it&#39;s like, okay, is this the part where we are we to believe that this is the guy stomping around upstairs? Because like also it&#39;s pretty obvious to us, the viewers, that this whole place is just stocked with ghosts. But yeah, so this time, like last time she</p>
                            <p>Bryan White (56:21.208)
                            but this time she like grabs a knife and goes exploring. And this is like really.</p>
                            <p>Queen Of The Lizard People (56:26.064)
                            This part is, this is a genuinely creepy moment.</p>
                            <p>Bryan White (56:29.368)
                            this part. Yeah. So when you asked me last week, like, what&#39;s the like spooky scene? And I said, like the Beverly D&#39;Angelo like scene in this movie, like this is really I think this is really the one that I was I was reaching for. I just you caught me off guard, God damn it, because this this whole scene is really, really strange. So she like walks.</p>
                            <p>Queen Of The Lizard People (56:48.31)
                            Unfortunately, they exercise no restraint. Like this could have been a lot better and it&#39;s not.</p>
                            <p>Bryan White (56:54.168)
                            It&#39;s still pretty good. I mean, I, yeah. Yeah, so like she&#39;s walking around and like she&#39;s got a flashlight and a knife. And like one of the first things that we see is she sees the cat in the hallway, literally eating the canary.</p>
                            <p>Queen Of The Lizard People (56:55.45)
                            It&#39;s very good, but like, well, go on.</p>
                            <p>Queen Of The Lizard People (57:08.25)
                            That&#39;s how you know the cat&#39;s the Satan.</p>
                            <p>Bryan White (57:10.008)
                            Uh huh, it ate the canary.</p>
                            <p>Queen Of The Lizard People (57:11.912)
                            The Canary? Not a Satanist.</p>
                            <p>Bryan White (57:13.888)
                            No, just a fucking victim. Yeah, whole thing spooking the hell out of me because there&#39;s there&#39;s like shadows moving around. Like she eventually comes up to one. I think actually I think she goes into that apartment, the one that&#39;s above her. And at one point, she is standing in a doorway. The doorway sort of like moves on its own. And you see just like a shadow, like a shape of a person on the other side of it. And that&#39;s what like it just sort of</p>
                            <p>Bryan White (57:43.908)
                            he just like moves away from her, just kind of like disappears. Yeah.</p>
                            <p>Queen Of The Lizard People (57:46.19)
                            Yeah, and he walks by her. That&#39;s what&#39;s creepy. It&#39;s like, it&#39;s as he&#39;s moving as though she&#39;s not there. It&#39;s when he attacks her that I&#39;m like, ah, you overplayed your hand.</p>
                            <p>Bryan White (57:52.269)
                            Yeah.</p>
                            <p>Bryan White (57:56.048)
                            Yeah, yeah, it&#39;s really like they could have. They probably could have bled a little bit more uncanny out of this, because that&#39;s really what works is just the fact that like, it&#39;s like almost like two scenes are playing out on top of each other. But yeah, like she follows him for some fucking reason. And the door opens and it&#39;s the ghost of her father. But he&#39;s like all like desiccated, nasty, his face all fucked up, his eyes are all. Really, I did not know that.</p>
                            <p>Queen Of The Lizard People (58:16.35)
                            you</p>
                            <p>Queen Of The Lizard People (58:21.07)
                            All this makeup, Rick Baker. Yep, he did all the makeup for this.</p>
                            <p>Bryan White (58:26.248)
                            It&#39;s pretty good. It&#39;s pretty good. And she. She she like she turns and she runs right. Because does she and then she runs because I know that she at one point she runs and she knocks over a table that has a detail on it that is really fucking wild because like I noticed this just this time and she knocks the table over the table was covered in razor blades. But yeah, she she she stabs the fuck out</p>
                            <p>Queen Of The Lizard People (58:35.514)
                            No, she stabs him.</p>
                            <p>Queen Of The Lizard People (58:51.494)
                            Yep.</p>
                            <p>Bryan White (58:56.028)
                            of them like really hits him a bunch of times like gouges the eye cuts his nose off. There is something so weird about the way that that scene that whole violent attack is shot because it&#39;s like stuttery and it&#39;s almost like it&#39;s yeah it&#39;s almost over.</p>
                            <p>Queen Of The Lizard People (58:57.95)
                            and then cuts his nose off.</p>
                            <p>Queen Of The Lizard People (59:10.47)
                            Everything is also very unnatural. Like the way like to cut a nose off is like that&#39;s, first of all, I don&#39;t think that&#39;s how that works. But yeah, the angle is just like, very odd. It&#39;s like if if if there were Dutch angles were people. That&#39;s what&#39;s happening in all of this.</p>
                            <p>Bryan White (59:16.948)
                            Because she slices up.</p>
                            <p>Bryan White (59:28.028)
                            Yeah, yeah. But it&#39;s almost like when they do the close ups of the knife, actually like cutting and gouging and stabbing. Like it&#39;s almost like the scene is over cranked with like a manual camera. It&#39;s crazy. She gets him in the eye and the eye just like, basically like oozes pus and shit. It&#39;s so, it&#39;s so crazy. And then she runs away. She goes out into the street and just freaks out and the whole neighborhood wakes up and comes out and enter the police.</p>
                            <p>Queen Of The Lizard People (59:55.31)
                            Which again, like it&#39;s pretty unrealistic that in what 1977, a woman yet people are going to be coming out into the street because a woman is screaming. I don&#39;t think so.</p>
                            <p>Bryan White (59:59.988)
                            Everybody comes out of the street. I know that whole yeah, right, right? We&#39;ve got what&#39;s that that kitty kitty Genovese? Yeah. Um. But yeah, so she&#39;s back in the hospital and the police show up. To the waiting room and this is where we first like this is the first time I was like, holy shit, I forgot Christopher Walken was in this because this is the first time we see him and he just like we said,</p>
                            <p>Queen Of The Lizard People (01:00:09.237)
                            Oh god.</p>
                            <p>Bryan White (01:00:30.669)
                            He doesn&#39;t say anything. They cut to him a bunch for like closeups and reaction shots, but he never speaks, which is just so off-putting. But.</p>
                            <p>Queen Of The Lizard People (01:00:38.43)
                            Is this, this is after Annie Hall, right?</p>
                            <p>Bryan White (01:00:42.51)
                            same year as Andy Hall.</p>
                            <p>Queen Of The Lizard People (01:00:44.672)
                            Because that&#39;s his first movie? No.</p>
                            <p>Bryan White (01:00:46.888)
                            I think, because a bunch of people from Annie Hall are also in this movie. But we find out through just this, this kind of antagonizing conversation that they have, like this cop knows Michael, and he has some kind of a history with the cops and his ex-wife. And we find out that</p>
                            <p>Queen Of The Lizard People (01:01:07.87)
                            This is also more of that melodrama where it&#39;s like, we feel like we&#39;re missing some critical piece of the story because the way he&#39;s talking to him is as though he&#39;s a serial killer. Where he&#39;s just like, I know you&#39;ve killed all these ladies. And it&#39;s like, what?</p>
                            <p>Bryan White (01:01:18.868)
                            Yeah, yeah.</p>
                            <p>Bryan White (01:01:23.248)
                            It&#39;s supposed to, yeah, it&#39;s definitely supposed to like hit as like a big surprise at this point, because up to this point, yeah, because up to this point, like Michael has been very like, just boyfriend, you know, like there&#39;s nothing really, there&#39;s, there&#39;s nothing really off about him yet. But then we find out that like, we know that he had, we know that he had a wife, I think. But now we find out that his wife died by suicide.</p>
                            <p>Queen Of The Lizard People (01:01:28.471)
                            I know you&#39;re part of this conspiracy.</p>
                            <p>Queen Of The Lizard People (01:01:52.59)
                            Yeah, she kills her. His wife kills herself because he&#39;s having an affair with the other one.</p>
                            <p>Bryan White (01:01:53.25)
                            Brooklyn.</p>
                            <p>Bryan White (01:01:57.708)
                            with Allison. Yeah. And then because of that we find out she attempts suicide.</p>
                            <p>Queen Of The Lizard People (01:02:05.01)
                            Which is like, ladies be fragile. Like, I&#39;m sorry, none of these women can handle infidelity?</p>
                            <p>Bryan White (01:02:09.182)
                            Yeah, she&#39;s...</p>
                            <p>Bryan White (01:02:13.309)
                            This is the second time that she has encountered some sort of traumatic encounter, and her first fucking reaction is to just end it.</p>
                            <p>Queen Of The Lizard People (01:02:22.83)
                            Just just run for that table full of razor blades.</p>
                            <p>Bryan White (01:02:27.048)
                            God.</p>
                            <p>Bryan White (01:02:31.008)
                            But yeah, so, but then there&#39;s another scene where Michael calls that P.I.&#39;s office. And it&#39;s like, oh, okay.</p>
                            <p>Queen Of The Lizard People (01:02:42.11)
                            the amazing receptionist.</p>
                            <p>Bryan White (01:02:45.848)
                            The receptionist is really funny. But it&#39;s, again, it&#39;s just sort of like inching the plot forward without, really without doing any sort of exposition, which is crazy because at the end, they&#39;re just, they just, at the end, they give it to you with both barrels, like, but up to this point, they&#39;re just kind of like dropping bits and pieces into the scene to sort of like let you know that, oh shit, this guy&#39;s fucked up too. But like this kind of gets back</p>
                            <p>Queen Of The Lizard People (01:03:01.09)
                            Yeah, don&#39;t worry, they&#39;re gonna take care of that at the end.</p>
                            <p>Bryan White (01:03:15.848)
                            to that, like, why is even this guy gaslighting her?</p>
                            <p>Bryan White (01:03:22.188)
                            because he doesn&#39;t really seem to have like a reason to like, is he, but like, this is one of the reasons why I think I had that sort of memory of him sort of being in on a conspiracy, where he definitely is just not in the way that I thought he was like, I thought that he was part of some like, satanic order that was trying to stop her because like, as we find out in the end, like, there&#39;s a bit of a straw, like a push and pull, but it&#39;s not in the way that I thought it was. But like,</p>
                            <p>Queen Of The Lizard People (01:03:47.93)
                            and not in a way that has anything to do with him. He&#39;s just sort of like a patronizing jerk.</p>
                            <p>Bryan White (01:03:50.509)
                            Right, cause...</p>
                            <p>Bryan White (01:03:53.848)
                            right? It&#39;s just it&#39;s so weird. Like, he&#39;s also like, why is he manipulating things? Because he very clearly sent that guy to her apartment. And she&#39;s also like what we find out because one of the things that she stabs the she stabs her father and she gets basically covered in blood. But nobody is found. No sort of crime scene is found in the place. But she definitely has</p>
                            <p>Bryan White (01:04:23.668)
                            Thank you.</p>
                            <p>Queen Of The Lizard People (01:04:23.79)
                            And also, they do eventually find the body. My question is, who moved it?</p>
                            <p>Bryan White (01:04:29.048)
                            Right, because it wasn&#39;t just that. So yeah, so this is the next scene. So there&#39;s a lot of back and forth, a lot of antagonism, and we find out it really just is a scene that sort of sets Michael up as a conspirator of some sort. He&#39;s definitely playing games for reasons we don&#39;t know, for reasons we don&#39;t know why, but he definitely sent...</p>
                            <p>Queen Of The Lizard People (01:04:46.69)
                            Well, here&#39;s my theory about this character, because this is at a time when they&#39;re doing a lot of like, the people are sort of getting clued in into like how, like defense attorneys work and stuff. And he is presented as being kind of a shady lawyer. Like he works for criminal types, and that&#39;s why he has these sort of criminal associates. Yeah, so he&#39;s sort of like a shady guy who is used to getting what he wants</p>
                            <p>Bryan White (01:05:03.588)
                            Yeah. Yeah. Yeah, he&#39;s got this like he&#39;s got this like rogues gallery that he could call on.</p>
                            <p>Queen Of The Lizard People (01:05:17.31)
                            underhanded ways of going about it. So I think that&#39;s kind of what they&#39;re driving at is like, he wants to marry her. He wants to, I guess, take care of her in a very seventies kind of way. And so to do that, he&#39;s sort of like circumventing her autonomy. And like.</p>
                            <p>Bryan White (01:05:37.008)
                            So he&#39;s driving her crazy in order to drive her towards him. That makes so much sense. Yeah.</p>
                            <p>Queen Of The Lizard People (01:05:40.691)
                            He&#39;s sort of, yeah, he&#39;s just trying to arrange it. He&#39;s like just trying to like arrange everything so he gets what he wants because he is a guy who gets what he wants. Like he&#39;s a shitty lawyer and he&#39;s the shitty person.</p>
                            <p>Bryan White (01:05:52.091)
                            That makes perfect sense. Like that just explains everything because in the end I&#39;m like, okay, he was definitely fucking with her, but why? Because he&#39;s not a part.</p>
                            <p>Queen Of The Lizard People (01:06:02.43)
                            Right, like you read it that way because everybody is doing that in this movie, also because of Rosemary&#39;s baby.</p>
                            <p>Bryan White (01:06:08.388)
                            Yeah, yeah. So, um, uh, now, uh, she, she goes to, she goes to confession, um, at this point, and this is where she encounters the, the priest from whatever this order is called. Um, and he&#39;s like, uh, you know, come back to Christ and, you know, relieve your burden. And he&#39;s really, no.</p>
                            <p>Queen Of The Lizard People (01:06:29.47)
                            And now here we&#39;re gonna go back to the Catholic thing though because what happens is she keeps getting confronted with these sort of, again, non-normative sex and her instinct is to run to the church. Like, it&#39;s just like, I don&#39;t know what&#39;s going on. You know what I should do? Go to church.</p>
                            <p>Bryan White (01:06:41.308)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:06:46.648)
                            Yeah, but she like it&#39;s very kind of it&#39;s kind of established throughout that like at one time she like she&#39;s a lapsed Catholic essentially. And she at one time she was probably quite resolute in her faith, but just kind of like drifted away over time or whatever. There is no God. Yeah. Yeah, she</p>
                            <p>Queen Of The Lizard People (01:07:04.932)
                            of the food sex, I mean...</p>
                            <p>Queen Of The Lizard People (01:07:08.59)
                            That&#39;s enough to scar anyone. FATHER!</p>
                            <p>Bryan White (01:07:17.189)
                            She, she, yeah, but she&#39;s constantly flirting with going back and every time somebody else in the movie is confronted with her faith, they&#39;re like, what the fuck are you going to church for?</p>
                            <p>Queen Of The Lizard People (01:07:27.79)
                            Which I mean, I&#39;m not gonna lie, that&#39;s kinda my reaction too. Like if you need help, that is not the place to go. You may as well go to the cops.</p>
                            <p>Bryan White (01:07:34.408)
                            Yeah, but yeah, so she runs into one of the one of the priests from the order from the beginning, you&#39;ve seen him, he kind of he&#39;s kind of lurking and in various scenes and he kind of Yeah, so he&#39;s part of that like group in Italy at the very beginning.</p>
                            <p>Queen Of The Lizard People (01:07:44.65)
                            Well, because he&#39;s in the beginning to.</p>
                            <p>Queen Of The Lizard People (01:07:49.072)
                            He&#39;s the leader of the Conclave.</p>
                            <p>Bryan White (01:07:51.508)
                            Yeah. So now we find out that.</p>
                            <p>Bryan White (01:08:00.248)
                            that there&#39;s a there&#39;s a body that&#39;s discovered, and it&#39;s the body of the private investigator that Michael hired. And all of his sort of wounds match the matches Allison&#39;s father&#39;s ghosts wounds. So the implication here is that she hallucinated and fucking murdered this PI who was sent there mostly just to drive her crazy.</p>
                            <p>Queen Of The Lizard People (01:08:04.816)
                            you</p>
                            <p>Queen Of The Lizard People (01:08:25.95)
                            This whole thing feels like it was like they added this in after. Because it doesn&#39;t like, if she killed a guy, there would be more to do about it.</p>
                            <p>Bryan White (01:08:33.028)
                            That, yeah, okay. Suck.</p>
                            <p>Bryan White (01:08:37.928)
                            Yeah, yeah, because I honestly the entire police angle feels a little wobbly to me because like everything else is very is very sturdy and solid in terms of like storytelling. But the police stuff almost feels like it was jammed in. Like it&#39;s probably from the from the novel. But like, I don&#39;t know what it is about it. It&#39;s just like it comes in in the second act and it feels very invasive.</p>
                            <p>Queen Of The Lizard People (01:09:04.349)
                            because you feel like you&#39;re missing half the story.</p>
                            <p>Bryan White (01:09:06.708)
                            right. And so she gets out of the hospital and they go she&#39;s she&#39;s like, I know it&#39;s real. I, you know, I killed somebody here and they go back to the apartment where it happened. And there&#39;s like no sign of, of any kind of violence whatsoever. And she remarks that I could have sworn that the carpet was gold because it&#39;s like a green rug now. But they go through some books on the shelves. And one of them is a book that she picks</p>
                            <p>Bryan White (01:09:36.667)
                            this, every page is the same, which I love this scene. Because again, this is another part where I&#39;m like, is he fucking with her right now? But it turns out that he&#39;s not. But he looks at it, he&#39;s like, what are you talking about? And she&#39;s like, every page is the same. It&#39;s all Latin. And he&#39;s like, what the fuck are you seeing that I&#39;m not seeing? And so they take it over and she he says, like, I need you to write down what you see in this book. And so she writes down a phrase in Latin and he&#39;s like,</p>
                            <p>Queen Of The Lizard People (01:09:40.05)
                            Yeah, that&#39;s a very good thing.</p>
                            <p>Bryan White (01:10:06.807)
                            like this book is in English like you and you see the pages so it&#39;s not like he&#39;s fucking with her but um this is when he takes he takes the phrase uh and he goes to some university and this is where martin balsam comes in who he is also having a fucking fantastic time like so uh he&#39;s we he what we walk in and he&#39;s like talking to like a student that i think he tutors</p>
                            <p>Queen Of The Lizard People (01:10:20.81)
                            Martin Balsam is amazing in this movie.</p>
                            <p>Queen Of The Lizard People (01:10:25.37)
                            He is kooky as hell.</p>
                            <p>Bryan White (01:10:36.987)
                            She&#39;s like, I&#39;ll see you next Wednesday for my tutorial. And he&#39;s like, that&#39;s when I go old time dancing. And then he&#39;s so he checks and he looks at his schedule and he&#39;s like, Oh, yeah, there you are. So when the hell do I go old time dancing?</p>
                            <p>Queen Of The Lizard People (01:10:41.996)
                            Yep. I&#39;ll do my old time dancing.</p>
                            <p>Queen Of The Lizard People (01:10:54.21)
                            Yeah, he has a very, God, who was Kolchak?</p>
                            <p>Bryan White (01:11:00.088)
                            Oh, Darren McGavin. Yes. Yeah, he...</p>
                            <p>Queen Of The Lizard People (01:11:01.15)
                            Yeah, Darren McGavin, he&#39;s very Darren McGavin approach to acting, because Martin Balsam is actually a very dramatic actor.</p>
                            <p>Bryan White (01:11:08.568)
                            Yeah, yeah. He also shows up in a bunch of like Michael Winter movies. He&#39;s in Death Wish 3 also. And again, overacting in that movie. But like, I don&#39;t know. I don&#39;t know. I mean, he probably was totally awesome to dudes, but like just miserable to everybody else. But yeah, he translates it. And it turns out that the phrase that Allison wrote down in Latin is from Paradise Lost, which he tells Michael, he&#39;s like, you just go get this book. It&#39;s in English.</p>
                            <p>Queen Of The Lizard People (01:11:16.61)
                            God, did these people owe him money?</p>
                            <p>Queen Of The Lizard People (01:11:37.15)
                            Yeah, this part&#39;s a little hacky. It&#39;s like, well, what&#39;s a thing that people have heard of?</p>
                            <p>Bryan White (01:11:38.548)
                            All right.</p>
                            <p>Bryan White (01:11:42.808)
                            Yeah, yeah, because this is the line is the is the classic abandoned hope all ye who enter here. It&#39;s that that part</p>
                            <p>Bryan White (01:11:51.708)
                            And a part of the also a part of the phrase is also the like the prayer that the priests are chanting at the very beginning, which is sort of like this is our lot. We you know, we we we guard that we guard the door. So yeah, in this happy place. It is it&#39;s like it&#39;s definitely like a weird mistranslation from like Greek or Latin or something like that, but.</p>
                            <p>Queen Of The Lizard People (01:12:07.37)
                            in this happy place. Just such a clumsy line.</p>
                            <p>Queen Of The Lizard People (01:12:19.63)
                            like I&#39;m with you I&#39;m with you and then what?</p>
                            <p>Bryan White (01:12:22.148)
                            Yeah. But so like if this seems very quick, it&#39;s just basically it&#39;s basically a cameo for Martin Balsam. But he then takes that information to the diocese and he completely falls into their trap when he tries to bullshit his way into Halloran&#39;s apartment. So Father Halloran is this priest. I don&#39;t know. I think up to this point, I may have neglected to even mention this fucking point. And it&#39;s pretty important. So this brownstone that Alison lives in is said to be completely unoccupied,</p>
                            <p>Bryan White (01:12:52.148)
                            apartment at the very top that is occupied by a priest who is just like senile and he sits in in the window and just looks out the window all day and you hear it in the trailer. If a gardener says he&#39;s blind to which Allison, what does he look at? Who knows? Yeah. So like.</p>
                            <p>Queen Of The Lizard People (01:13:04.69)
                            And she says, well, what does he look at? Bitch, don&#39;t be so judgy.</p>
                            <p>Queen Of The Lizard People (01:13:10.41)
                            business. There is that great line though, when they go back and you know, she&#39;s looking at the building in Avogard and it looks up and she goes, Oh, no, he&#39;s really there.</p>
                            <p>Bryan White (01:13:18.648)
                            Yeah. Yeah. Right. You crazy asshole. So but yes, so so everybody knows his name. Like it&#39;s not a secret. But Michael wants to wants to know who he is, basically. So he goes to the diocese and he tries to like bullshit his way into their records by telling him that he&#39;s about to like inherit some money or something like that. They the the the priest that he&#39;s dealing with, again, is one of these guys from this like hidden order in the church.</p>
                            <p>Bryan White (01:13:48.748)
                            who just sees through him immediately and he&#39;s like, bullshit, we take care of him. Don&#39;t worry about it. And then he tries, he shows him that that phrase, which this which hits the priests a little funny, because like it&#39;s part, you know, it&#39;s part of their prayer. But again, like,</p>
                            <p>Queen Of The Lizard People (01:13:53.616)
                            you</p>
                            <p>Queen Of The Lizard People (01:14:00.21)
                            Yeah. Like, they may as well just inserted a sting right there. Like a dark shadows sting.</p>
                            <p>Bryan White (01:14:05.548)
                            Yeah, yeah. Oh, my God, like, that&#39;s actually one of my notes is a lot of these are parts of this movie that are very dark shadows. It&#39;s so melodramatic.</p>
                            <p>Queen Of The Lizard People (01:14:16.45)
                            particularly the overacting.</p>
                            <p>Bryan White (01:14:19.511)
                            Yeah, yeah. And like lots of dusty shit and chandeliers.</p>
                            <p>Queen Of The Lizard People (01:14:22.41)
                            Yep. I mean, John Carradine&#39;s hair is just makeup. It&#39;s just like pancake makeup in his hair.</p>
                            <p>Bryan White (01:14:28.828)
                            Well, that&#39;s the thing is, I was like, when you see him, I was like, for the longest time, I was like, are they just, did they just like go cheap and just dust his hair to make it gray? And then I realized he&#39;s also got dust on his shoulders. So the implication is he just sits in the chair and literally collects dust.</p>
                            <p>Queen Of The Lizard People (01:14:43.51)
                            But it&#39;s like clearly just makeup because I thought the same thing and I was like, he has gray hair at that point.</p>
                            <p>Bryan White (01:14:45.31)
                            Oh yeah.</p>
                            <p>Bryan White (01:14:48.328)
                            Yeah, it&#39;s very chunky. But yeah, that&#39;s that&#39;s the whole thing is he&#39;s just it&#39;s to apply that he never moves. So like completely thwarted Michaels next move is to hire a burglar. William Hickey, who you might know from puppet master as Toulon and he&#39;s also in Christmas vacation. He&#39;s in a lot of shit. You&#39;ve seen him.</p>
                            <p>Queen Of The Lizard People (01:15:00.191)
                            William Hickey.</p>
                            <p>Queen Of The Lizard People (01:15:07.47)
                            Anytime you need someone with a very, very strange voice. He&#39;s your guy.</p>
                            <p>Bryan White (01:15:11.748)
                            He&#39;s got like a crazy like, yeah, like a sleazy criminal guy. He plays a lot of gangsters. Oh, yeah, I could. I can I can see that. Yeah. But like I at first, I didn&#39;t recognize him because the the way making that I know is like an old guy. And he&#39;s he&#39;s definitely aging in this, but like his hair is not gray and he&#39;s not like completely saggy or anything like that. So I had to look him up and I was like, oh, shit, it&#39;s him. But this guy.</p>
                            <p>Queen Of The Lizard People (01:15:16.87)
                            But also like, New York Jew. Like, stereotypical New York Jew.</p>
                            <p>Queen Of The Lizard People (01:15:39.81)
                            I always remember him from the Tales from the Crypt episode that he&#39;s in where he like ages backwards or something, but he still has the same voice.</p>
                            <p>Bryan White (01:15:48.848)
                            Oh yeah, yeah. Yeah, he was in, he was also in Tales from the Dark Side, so now I gotta look that up. But I really like this scene because like, first of all, he&#39;s just a really great criminal actor, like he does really well, like he&#39;s definitely very used to playing lowlifes. So this guy can open anything, so they get into the, they go into the diocese after hours, and they&#39;ve got, he can even open those like, crazy-ass medieval locks that they&#39;ve got on their records.</p>
                            <p>Queen Of The Lizard People (01:16:16.73)
                            And like it&#39;s nothing, like he&#39;s opening his front door. Takes him two seconds.</p>
                            <p>Bryan White (01:16:20.028)
                            Right. And this is the thing. He we learned that Halloran is is this is a big like exposition. We learn. We learned that Halloran is just the latest in a chain of people who disappear from their normal lives. And they change their names and then they become either a priest or a nun and take occupancy in the house. And not just that, but there&#39;s a file for Allison who</p>
                            <p>Queen Of The Lizard People (01:16:28.61)
                            Yeah, this is exposition dump one.</p>
                            <p>Bryan White (01:16:51.611)
                            and Michael has 24 hours to avert something. So basically we&#39;re rounding the corner into the third act here.</p>
                            <p>Queen Of The Lizard People (01:17:00.43)
                            But it&#39;s like they get to the third act and somebody on the set of producers, somebody was like, we haven&#39;t explained any of this stuff yet. And they were like, oh, shit, what do we do? Jam it all in at the end.</p>
                            <p>Bryan White (01:17:10.848)
                            This really is, because like, this is the thing, like right now you get like the big dump right there in that scene and then coming up very, very soon is the next big one. So, Michael, basically, because like there is like there you really are very disoriented up to this point because like there&#39;s very. Oh, you&#39;re fucked. Yep.</p>
                            <p>Queen Of The Lizard People (01:17:24.75)
                            That&#39;s the one for all the people in the back.</p>
                            <p>Queen Of The Lizard People (01:17:32.31)
                            If you&#39;ve been on your phone for the first half of this movie, don&#39;t worry, we&#39;ll get to it again.</p>
                            <p>Bryan White (01:17:41.068)
                            he needs to go to the house, but he&#39;s got he can&#39;t bring Allison with him. So he leaves her and basically with her friend at a party and she looks fucking terrible. She&#39;s very, very sick, like she&#39;s been getting sicker and sicker. That&#39;s the thing. And I think really, this is a part that just never gets, that never gets explained. But I think that what&#39;s what&#39;s happening is with the headaches, the sort of declining health, it would have been nice if they said</p>
                            <p>Queen Of The Lizard People (01:17:53.01)
                            Like that part makes no sense. Why is she dying?</p>
                            <p>Bryan White (01:18:10.848)
                            was it&#39;s a supernatural process of becoming the next Sentinel. And so she&#39;s because what happens is she doesn&#39;t just sit down in the chair. Like what we see at the very end is she&#39;s like old and like her whole body has changed. And so like exactly it&#39;s just it&#39;s cool. But I think I.</p>
                            <p>Queen Of The Lizard People (01:18:27.51)
                            And that&#39;s gonna make no sense.</p>
                            <p>Queen Of The Lizard People (01:18:32.27)
                            I think the problem is part of the premise that this movie, there&#39;s a lot of supernatural stuff happening, but they never commit to it enough that it feels natural. Like it doesn&#39;t feel like it&#39;s part of the story. So when it happens, it&#39;s like, wait, what the fuck is going on? Why is she a hundred now?</p>
                            <p>Bryan White (01:18:40.611)
                            Yeah.</p>
                            <p>Bryan White (01:18:45.308)
                            Right, because like this is the thing. It took me like two viewings in like 24 hours for me to go. OK, I think that like the reason that she&#39;s like she&#39;s undergoing this decline is because she&#39;s kind of dying because what happens is you don&#39;t just change your name. I think like the old you dies and the new Sentinel is effectively a different person maybe. Yeah.</p>
                            <p>Queen Of The Lizard People (01:19:09.85)
                            And that&#39;s Exposition Dump 1, where they go through all of them and it&#39;s like, look at the fun old-timey photographs.</p>
                            <p>Bryan White (01:19:15.608)
                            Kind of like, yeah, kind of like they&#39;re like an anti-saint sort of, because like it&#39;s super Catholic and the reason that all of the... Yeah, because right, because you can&#39;t just become a sentinel. Like you&#39;re chosen because of your moral frailty.</p>
                            <p>Queen Of The Lizard People (01:19:21.35)
                            and judgy as hell. Jesus.</p>
                            <p>Queen Of The Lizard People (01:19:31.55)
                            Yeah, and the thing is, it&#39;s like, oh, they, you know, he, Halloran is there because he tried to commit suicide and Allison is there because she tried. It&#39;s like, yeah, they tried, they didn&#39;t actually do it.</p>
                            <p>Bryan White (01:19:42.408)
                            right but you&#39;re still doomed god damn it you&#39;re spitting in the yeah you&#39;re spitting in the face of god so um he yeah so he leaves and he goes back to the house and she&#39;s um she&#39;s at this party and she has another one of these like a really bad episode uh and they put her in a bedroom and then she uses the opportunity to give everybody the slip and heads back to her apartment where Michael is</p>
                            <p>Queen Of The Lizard People (01:19:44.871)
                            Again, there&#39;s that fucking moralism.</p>
                            <p>Queen Of The Lizard People (01:20:07.75)
                            This is the first time Allison does anything for herself.</p>
                            <p>Bryan White (01:20:10.948)
                            Yeah. And for some reason, well, I mean, I think the reason that she did it is because she&#39;s mystically drawn to it. But yeah.</p>
                            <p>Queen Of The Lizard People (01:20:16.77)
                            Also, how else are you going to end this movie?</p>
                            <p>Bryan White (01:20:18.968)
                            fucking seriously. But yeah, so he&#39;s in the place and he smashes through there&#39;s there&#39;s a piece of the scenery that we see a couple of times that sort of remarked on as as unique. It&#39;s basically it&#39;s just like panel in a wall next to the mailboxes. And for whatever reason, he decides to smash through it. And it&#39;s the sign bearing that message that she wrote down. It&#39;s the, you know, you&#39;re now entering the city of grief, abandoned hope all ye who enter here. So the whole thing is,</p>
                            <p>Bryan White (01:20:48.948)
                            yet this is the gate to hell, or at least one of them.</p>
                            <p>Bryan White (01:20:55.068)
                            And so he goes for some reason, I don&#39;t know why. Well, actually, yeah, this is the part where this is the thing is for some reason, for some reason, Halloran has seen to seen fit to get up and leave his position, his seat up there. And he comes down and he&#39;s actually the one who speaks it, because Michael says the phrase out loud as he reads it. And just as he&#39;s about to say the last line, there&#39;s John Carradine behind him, like all eyes whited out and everything and like covered in dust. Finally says it. And he says, Oh, yeah, abandon all hope.</p>
                            <p>Queen Of The Lizard People (01:20:59.151)
                            Yeah, this it goes off the rails at this point. You can&#39;t really ask a lot of questions.</p>
                            <p>Queen Of The Lizard People (01:21:20.69)
                            who finally says something.</p>
                            <p>Bryan White (01:21:24.973)
                            you</p>
                            <p>Queen Of The Lizard People (01:21:25.05)
                            And listen, this could not have been a matter of money because John Carradine would be in anything.</p>
                            <p>Bryan White (01:21:31.536)
                            Never saw a paycheck he didn&#39;t like.</p>
                            <p>Queen Of The Lizard People (01:21:33.491)
                            Incidentally, my favorite Dracula. Well, 100%.</p>
                            <p>Bryan White (01:21:35.968)
                            Really? No shit. But yeah, so like now, but then like he immediately turns. It&#39;s just a jump scare moment, I guess, or something like just to sort of put you on off your game because then he immediately turns around and goes back upstairs and he&#39;s like, Michael&#39;s trying to talk to him, but he won&#39;t respond. He&#39;s just chanting Latin as he goes back up. And then for some reason, Michael decides to strangle him. And he does the whole thing where he doesn&#39;t just like</p>
                            <p>Queen Of The Lizard People (01:22:02.016)
                            Yeah.</p>
                            <p>Bryan White (01:22:05.588)
                            neck, but he&#39;s like shaking him vigorously. And you don&#39;t see you don&#39;t see it happen. But like he gets gets bludgeoned by a statue. This is serious business, man.</p>
                            <p>Queen Of The Lizard People (01:22:10.85)
                            Until Michael is then killed by a priest.</p>
                            <p>Queen Of The Lizard People (01:22:17.43)
                            Not very priestly, if you ask me.</p>
                            <p>Queen Of The Lizard People (01:22:22.47)
                            So I think we gotta revisit something for a minute here because as far as the movie has told us, Allison&#39;s options are thus. Option one is, I don&#39;t know, either like hang out there and live there, or maybe also you have to kill yourself. Either way, like hang out with these weird queer people who have parties for cats and are kind of fun, or become a nun?</p>
                            <p>Bryan White (01:22:47.193)
                            I&#39;m sorry.</p>
                            <p>Bryan White (01:22:50.168)
                            Yeah, yeah, that&#39;s her.</p>
                            <p>Queen Of The Lizard People (01:22:52.05)
                            I think the answer is pretty obvious. Go with the gaze.</p>
                            <p>Bryan White (01:22:54.768)
                            I would hang out with the party people. Oh, but oh also, this is the second time that they do it, but they refer they refer to Sylvia Miles and Beverly D&#39;Angelo for the second time as capital T capital L the lesbians.</p>
                            <p>Queen Of The Lizard People (01:23:13.81)
                            Yep. And you also get another you get the sister. So this is what the part where she&#39;s like running through the house, right?</p>
                            <p>Bryan White (01:23:19.048)
                            Right, so she goes back into the place and like all hell is starting to break loose. Yeah, so there&#39;s, yeah, there&#39;s a whole scene in the in the police station where they&#39;re running through basically their mug shots and we find out that they&#39;re all like murderers of some sort. And so she&#39;s, Allison is back in her place and she runs. I know, I know, I fucking love it though.</p>
                            <p>Queen Of The Lizard People (01:23:21.67)
                            because they run down who all of these people actually were. Because surprise everybody, they&#39;re dead.</p>
                            <p>Queen Of The Lizard People (01:23:41.19)
                            This ending is problematic as fuck.</p>
                            <p>Queen Of The Lizard People (01:23:47.35)
                            Oh, I... I don&#39;t know. The hard one.</p>
                            <p>Bryan White (01:23:49.248)
                            What? Yeah, but she&#39;s like, so she&#39;s in the house and she&#39;s running around. She&#39;s very scared now. She hears approaching footsteps. So she hides in a wardrobe. And it&#39;s like it&#39;s really like this is a year before Halloween. So I can&#39;t say that they were pulling it off of Laurie Strode, but it&#39;s like that. And then somebody opens up the door and we find out it&#39;s Michael who&#39;s fine. One hundred percent looking good. But he is about he is about to dump.</p>
                            <p>Bryan White (01:24:19.528)
                            everything on her. It is like a five minute long uninterrupted dialogue dump of yeah for everybody who&#39;s just been following along here&#39;s the deal. This is the gateway to hell and there&#39;s a person like a priest who has to sit at the top and sort of maintain a vigil over it and you&#39;re going to be the next one. By the way I&#39;m dead because and I&#39;m damned so I&#39;m stuck here for all eternity because I killed my wife and I tried to try to</p>
                            <p>Queen Of The Lizard People (01:24:20.811)
                            Yeah. He is doing it for the back rows at this point.</p>
                            <p>Bryan White (01:24:49.729)
                            And that&#39;s when he turns his head and it&#39;s all mashed up and shit. Yep.</p>
                            <p>Queen Of The Lizard People (01:24:53.75)
                            again, Rick Baker, which is what makes the actual end of this movie kind of egregious. You have someone who is insanely talented. And yet.</p>
                            <p>Bryan White (01:24:58.888)
                            I know, God, I know, but this is the thing is once he delivers that line that he&#39;s dead, I&#39;m damned and all that, they do this awesome cut to like a close-up of his face where there&#39;s like a musical sting and then his face starts to like break apart, like rips and tears in the flesh appear. And on VHS and in like 35, this must have been awesome,</p>
                            <p>Queen Of The Lizard People (01:25:28.29)
                            Yeah, not so much.</p>
                            <p>Bryan White (01:25:28.708)
                            deaf blu-ray. Oh my God. You can see the strings that pulled up that pull the effects apart. But it&#39;s still cool. It&#39;s still cool.</p>
                            <p>Queen Of The Lizard People (01:25:37.251)
                            It&#39;s like right up there with Hellraiser.</p>
                            <p>Bryan White (01:25:41.348)
                            Yep. Yep. Except those are chains. But uh she so yeah but now she knows she&#39;s got to get up there and because of her status as a sinner she she has to take the place of the old priest become the new sentinel. Or well this is what Burgess Meredith he and he he intercepts her and this is where all the devils come out.</p>
                            <p>Queen Of The Lizard People (01:25:56.431)
                            or...</p>
                            <p>Queen Of The Lizard People (01:26:02.87)
                            And he is incredible in this part. He&#39;s like, this is the saving grace of an otherwise kind of morally reprehensible moment.</p>
                            <p>Bryan White (01:26:05.248)
                            This is.</p>
                            <p>Bryan White (01:26:11.488)
                            I yeah, this the way he delivers this, I fucking love it because it&#39;s such a wild turn of character because this is this is when he truly becomes sinister. And he&#39;s like, he kind of takes the story where he&#39;s like you&#39;re the next Sentinel but like you you&#39;ll become our Queen and you&#39;ll stay here forever and you just have to kill yourself and she almost does it, but at the same time. yeah.</p>
                            <p>Queen Of The Lizard People (01:26:32.61)
                            Yeah, because she&#39;s like, well, party for a cat. Weird lesbians downstairs. But this is also when she sees the sisters are also like having some weird lesbian moment where they&#39;re eating a body in the hallway or something. Yeah. So it&#39;s like, this is also a weird sex thing again. Maybe, I don&#39;t know.</p>
                            <p>Bryan White (01:26:46.348)
                            Oh yeah, they&#39;re eating Michael&#39;s brains. And um.</p>
                            <p>Bryan White (01:26:53.328)
                            kind of, well, it&#39;s just because they&#39;re all naked. But at the same time this is happening, all the other devils are coming up from the, you know, the abyss. They&#39;re walking around. And the thing is, and they&#39;re all horrifically deformed, but here&#39;s the thing, and this is the part that makes Dave squirm, these are all people with real deformities.</p>
                            <p>Queen Of The Lizard People (01:27:10.85)
                            Thanks.</p>
                            <p>Queen Of The Lizard People (01:27:13.97)
                            And so, but the conflating deformity and sort of abnormalities with evil when it&#39;s actual deformed and people with different abilities is kind of gross. Especially when you have someone who has been doing special effects for your entire movie and this is, you end it with this sort of circus of deformity.</p>
                            <p>Bryan White (01:27:30.311)
                            I don&#39;t disagree. I definitely.</p>
                            <p>Bryan White (01:27:40.508)
                            Oh yeah, it&#39;s definitely, it&#39;s the end of freaks, basically, is what&#39;s happening here.</p>
                            <p>Queen Of The Lizard People (01:27:45.77)
                            But even Freaks, I think, handled that more tastefully than this does. And apparently this was like, this outraged people at the time as well as being sort of, you know, obviously we would probably call it ableist now, but even then they were like, this is just exploitation of the worst order.</p>
                            <p>Bryan White (01:28:02.169)
                            Oh, it&#39;s total exploitation. I had read that winner&#39;s excuse is just like, this would have taken us a million years to put makeup on people. And like, we only had so much money. I said, essentially. But also the fact that these are like real deformities makes this, I don&#39;t know, it&#39;s not good, but it&#39;s effective in a way that I don&#39;t think</p>
                            <p>Queen Of The Lizard People (01:28:12.371)
                            I had women to sexually harass.</p>
                            <p>Bryan White (01:28:30.448)
                            could have been like it really, it really does. I don&#39;t know. I, it&#39;s something I&#39;m going to have to reconcile, but I.</p>
                            <p>Queen Of The Lizard People (01:28:31.35)
                            I guess. Like the low key homophobia in this movie doesn&#39;t bother me, but this part I was kind of like, oh, all right, fine, I guess. The only saving grace is that Burgess Meredith is like fucking swinging for the fences at this point. And he&#39;s a small man, so to like see him climbing on, like he climbs on the furniture, he climbs on a table, and it&#39;s the sort of like, he&#39;s like a animal almost. It&#39;s very effective.</p>
                            <p>Bryan White (01:28:41.988)
                            Yeah.</p>
                            <p>Bryan White (01:28:49.389)
                            Oh yeah, like this is this is his.</p>
                            <p>Bryan White (01:29:00.108)
                            He&#39;s very, this is his like ultimate villain monologue. It&#39;s so great. Yeah. Have you ever seen the movie, A Dark Song?</p>
                            <p>Queen Of The Lizard People (01:29:05.23)
                            And it has a very kind of Shakespearean delivery.</p>
                            <p>Queen Of The Lizard People (01:29:11.73)
                            the Irish one?</p>
                            <p>Bryan White (01:29:13.089)
                            Uh, it&#39;s, I think it&#39;s like Irish or Scottish about the woman who</p>
                            <p>Queen Of The Lizard People (01:29:16.09)
                            But yes, you loved it. I liked it up until the end. And I thought it has a fucking ridiculous ending.</p>
                            <p>Bryan White (01:29:20.788)
                            I fucking I love the ending that that. Yeah, yeah. Highly recommended, I love it to death, but yeah, the end of that movie reminds me of this one, because like Allison&#39;s like running through the place while all of these people are kind of coming for her. And that&#39;s that also happens in that movie until she sort of reaches the top and sort of find salvation with the angel, which drives them away. All the all the devils. But like what happens is they&#39;re they&#39;re they&#39;re sort of they&#39;re confusing her. They&#39;re telling her like,</p>
                            <p>Queen Of The Lizard People (01:29:22.47)
                            That&#39;s the one with the angel at the end. Yeah, that bugged the crap out of me.</p>
                            <p>Bryan White (01:29:50.668)
                            kill yourself in order to sort of take your place. But really, what happens is if she kills herself, she&#39;s just fucking doomed. So just as she&#39;s about to do it, Father Halloran and the priests show up with a big gold cross and they like, you know, like back you demons like that sort of thing.</p>
                            <p>Queen Of The Lizard People (01:30:07.37)
                            Yeah, this is very like the fog. It&#39;s like the cross from the fog.</p>
                            <p>Bryan White (01:30:10.528)
                            Yes, yeah, and so like they, you know, of course, the, you know, the cross and the sort of holy symbols make all of the devil sort of shrink away. But she she&#39;s handed the cross, she takes her place in the in the in the chair and like all of the devil&#39;s sort of shrink away and vanish like a little bit at a time. And I love I love the way that that they do because they all sort of exit through the door except for the one woman who kind of backs herself into the wall. And then</p>
                            <p>Queen Of The Lizard People (01:30:39.572)
                            like Homer disappearing into the shrubs.</p>
                            <p>Bryan White (01:30:41.289)
                            Yes, and she, and she&#39;s just gonna like sticks there and they cut back to Meredith who&#39;s who&#39;s reacting essentially he&#39;s very disappointed he&#39;s very angry he&#39;s cursing them all and then they cut back in the room is just empty it&#39;s it&#39;s so fucking cool.</p>
                            <p>Queen Of The Lizard People (01:30:56.57)
                            And he plays a sort of so is burnt burnt offerings is before this, I think it might have been the year before or a year or two before he plays a similar character. He&#39;s not as sort of outwardly menacing because he&#39;s only he&#39;s not in burnt offerings for that long, but it&#39;s a very similar story, which is like another kind of knockoff.</p>
                            <p>Bryan White (01:31:00.489)
                            Uh, yeah. Yeah.</p>
                            <p>Bryan White (01:31:09.228)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:31:13.808)
                            So she, so the, the mantle has been, has been passed. And I, I&#39;m not really sure. I think, I think Calleran dies like, but it&#39;s never, you don&#39;t see it. I think that&#39;s just the implication. And we find out that the new, so, so what happens is cut, you see like a wrecking ball smashing into like an ivy, like covered brick wall, which is, you know, supposed to look like the exterior of this building. And they&#39;re putting it up and you hear Ava Gardner like selling the place to a new couple.</p>
                            <p>Queen Of The Lizard People (01:31:40.13)
                            And who is she selling that? Who is the man of this couple? That is Tom Berenger.</p>
                            <p>Bryan White (01:31:43.488)
                            Is it Tom Berenger? Tom Berenger, yeah, another weird like just, you know, early, early role cameo. Apparently Richard Dreyfuss is in this for a second also. Yeah. But like, but he wasn&#39;t like a small potatoes actor. Like he&#39;d already done Jaws at this point. Yeah. But like she gives him the same thing. Like the place is basically empty. This guy over here is very nice, quiet. And there&#39;s a, there&#39;s a woman at the top who, she, you know, she&#39;ll never bother you. She&#39;s a bit of a recluse. She&#39;s a nun. And then we cut</p>
                            <p>Queen Of The Lizard People (01:31:57.39)
                            Alright, don&#39;t jump.</p>
                            <p>Bryan White (01:32:13.348)
                            it&#39;s it&#39;s Allison in the nuns habit and she&#39;s all like but now she&#39;s very old and her skin is like you know sort of wrinkled and white and her eyes are also whited out and like she&#39;s like she&#39;s blind. Yeah, and it makes me wonder like if Fulci had seen this because he does this a few years later in the beyond like seeing like seeing hell makes you blind.</p>
                            <p>Queen Of The Lizard People (01:32:24.17)
                            She&#39;s blind.</p>
                            <p>Queen Of The Lizard People (01:32:30.451)
                            In the beyond.</p>
                            <p>Queen Of The Lizard People (01:32:34.65)
                            So I got two questions about this ending. One, wouldn&#39;t Ava Gardner know that that&#39;s her? Because clearly not that much time has passed, so what the fuck?</p>
                            <p>Bryan White (01:32:46.611)
                            I mean, maybe, but like also like who knows if she&#39;s even seen her. She just knows that sister Teresa lives up there.</p>
                            <p>Queen Of The Lizard People (01:32:52.73)
                            Second question, where did they put Allison while they were building this new house?</p>
                            <p>Bryan White (01:32:57.908)
                            I know that kind of came over me as well. Like did she do they just like stick her in a chair off to the side while they built a new one? Like, yeah, the church put her up in like some sweet digs.</p>
                            <p>Queen Of The Lizard People (01:33:03.951)
                            Is she in a hotel?</p>
                            <p>Queen Of The Lizard People (01:33:08.43)
                            I mean, someone&#39;s gotta be there to guard the gate to hell as you can&#39;t leave. What do you do when you&#39;re renovating the gate to hell?</p>
                            <p>Bryan White (01:33:12.909)
                            No.</p>
                            <p>Bryan White (01:33:17.271)
                            Yeah, they tore the whole building down around her except for like the stairs and her apartment, and then they just built around it.</p>
                            <p>Queen Of The Lizard People (01:33:24.33)
                            just up there on a little platform. Like a high dive horse.</p>
                            <p>Bryan White (01:33:26.048)
                            Yep. So, yep. So then, so that&#39;s it. Roll credits. The the end. That&#39;s the Sentinel. Woo.</p>
                            <p>Queen Of The Lizard People (01:33:32.25)
                            Woof. That is a... That&#39;s a, that&#39;s something. I remembered it being better. And I think the first half is still a banger. The second half, ehh, bit of a nosedive.</p>
                            <p>Bryan White (01:33:36.108)
                            That&#39;s a hell of a movie.</p>
                            <p>Bryan White (01:33:40.276)
                            I-</p>
                            <p>Bryan White (01:33:44.728)
                            I see the thing is, is I kind of feel the opposite. Like I feel I like I like the back half mostly just because that&#39;s where all the crazy shit happens. But all in all, like I was worried going into it to watch it again, that I was going to come out of this the way that I did with Maniac and be like, this isn&#39;t the movie that I remember it being. But it very much is like it&#39;s it&#39;s definitely wicked wobbly.</p>
                            <p>Queen Of The Lizard People (01:34:11.01)
                            Oh, it would be garbage. Like the reason Maniac is still regarded at all really is because it was made with nothing. This, the reason this movie holds up in any way is because it has a competent cast and that&#39;s about it. The directing, the direction of this movie, I think is probably dog shit. That&#39;s why it&#39;s so uneven and weird. And the script is a little bit questionable, but because the cast is what it is, they really hold it together.</p>
                            <p>Bryan White (01:34:19.311)
                            Yeah.</p>
                            <p>Bryan White (01:34:25.129)
                            Yep.</p>
                            <p>Bryan White (01:34:32.788)
                            Yeah. Yeah.</p>
                            <p>Bryan White (01:34:40.328)
                            Right, right. Like and I&#39;ll tell you, as far as like, like as far as horror, like haunted house horror movies go, this is probably my favorite. There&#39;s not a wide menu. Like, I mean, nowadays there are a shitload of modern haunted house movies, but I really don&#39;t care for those. Like the James Wan stuff doesn&#39;t really do anything for me.</p>
                            <p>Queen Of The Lizard People (01:35:00.775)
                            Yeah, this definitely sticks pretty close to the gothic.</p>
                            <p>Bryan White (01:35:05.068)
                            Yeah, yeah, just overall, like I think it&#39;s I think it&#39;s easily it&#39;s the best one. It&#39;s got to it really it really works for me. I just I enjoy it so much.</p>
                            <p>Queen Of The Lizard People (01:35:14.69)
                            I mean, there&#39;s so many loose ends and unanswered questions that it&#39;s just kind of like, and most of them you&#39;re like, I don&#39;t really care.</p>
                            <p>Bryan White (01:35:21.448)
                            That&#39;s the thing, like, ordinarily, like, until we started, like, talking it out, I was just resigned to be like, what, like, just to be like, what the fuck is going on? Why is literally everybody messing with this poor girl? But then, like, we hacked it out and figured, you know, sort of figured it out. But like, that everybody is literally just sort of like pushing this poor woman around.</p>
                            <p>Queen Of The Lizard People (01:35:44.19)
                            I think that that speaks a lot to the the direction of this movie is like they&#39;re all just doing the best they can with what they think they&#39;re supposed to be doing, which is why they&#39;re all just doing Rosemary&#39;s baby. Because it sounds like the guy who directed sucked and was probably just like, I don&#39;t know, blowing lines or like harassing women when he should have been directing a movie.</p>
                            <p>Bryan White (01:35:50.898)
                            Ha ha.</p>
                            <p>Bryan White (01:35:55.768)
                            Yeah, yeah.</p>
                            <p>Bryan White (01:36:03.408)
                            That&#39;s the thing. I just think, and so like garbage director for this one, garbage director for Rosemary&#39;s Baby. It sounds like making movies in the 70s fucking sucked. I&#39;m sorry, I&#39;m.</p>
                            <p>Queen Of The Lizard People (01:36:16.69)
                            Yeah, I told you I just read that Chinatown book and man it will you will be disillusioned by that one.</p>
                            <p>Bryan White (01:36:21.548)
                            Yeah, the whole the whole auteur thing can just fuck itself. And this is really. Yeah, like this is this is I think this was probably really like peak auteur season, like after. Like after 77, like it really kind of comes apart, like Kubrick is probably the last guy who can really get away with it, with the shining. But like after that, yeah, well, what&#39;s the thing? Like after that, Spielberg is the guy who really goes on to sort of define</p>
                            <p>Queen Of The Lizard People (01:36:25.19)
                            Here&#39;s a bunch of narcissistic men.</p>
                            <p>Queen Of The Lizard People (01:36:33.03)
                            Oh yeah, this is when they, that&#39;s the end of that era.</p>
                            <p>Queen Of The Lizard People (01:36:43.911)
                            Spielberg. Just the two of them.</p>
                            <p>Bryan White (01:36:51.508)
                            Hollywood. Yeah, yeah. Because like he was still he you know, but the thing I also I think that Steven Spielberg went at the whole thing with an entirely different vision than everybody else did where he was very self aware of making popcorn, where I think like Roman Polanski, fucking thought that he was changing the world, you know.</p>
                            <p>Queen Of The Lizard People (01:36:52.15)
                            because you have to be bankable.</p>
                            <p>Queen Of The Lizard People (01:37:12.85)
                            And man, Rosemary&#39;s Baby is an incredible movie. And it is probably, you know, it was because of, a lot of it is because of his direction. Unfortunately, he is a pedophile. He is a sex pervert.</p>
                            <p>Bryan White (01:37:16.309)
                            It&#39;s a great movie.</p>
                            <p>Bryan White (01:37:23.488)
                            Yep. Yep.</p>
                            <p>Bryan White (01:37:26.688)
                            Sex pest. It&#39;s just sex pests all the way down.</p>
                            <p>Queen Of The Lizard People (01:37:29.37)
                            It reminds me of that. My favorite line from American Dad, Stan, I am a sex offender. I love offensive sex.</p>
                            <p>Bryan White (01:37:35.348)
                            Ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha ha</p>
                            <p>Bryan White (01:37:41.471)
                            Oh, so what are we doing next?</p>
                            <p>Queen Of The Lizard People (01:37:44.151)
                            Ah, well, I feel like I need something fun.</p>
                            <p>Bryan White (01:37:48.348)
                            Really, we&#39;ve done a couple of drags in a row. I think we need to get back to something a little goofy.</p>
                            <p>Queen Of The Lizard People (01:37:53.67)
                            So I&#39;ve been pushing the howling for a while. That&#39;s kind of a fun one. Or I don&#39;t know. Or not just as you get to John Carradine and Slim Pickens. The other option that I also have been pushing is Killer Clowns from Outer Space.</p>
                            <p>Bryan White (01:37:56.168)
                            Yeah. John Carradine&#39;s in that too.</p>
                            <p>Bryan White (01:38:10.408)
                            Oh, yes. That&#39;s a tough call. Let&#39;s you know, just because I just because I absolutely adore the howling. Let&#39;s do the howling. All right. All right. See us back here in two for when we do the howling.</p>
                            <p>Queen Of The Lizard People (01:38:13.19)
                            I fucking love that movie. I love them both, actually.</p>
                            <p>Queen Of The Lizard People (01:38:22.09)
                            All right, the howling it is.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-howling">Next Episode</Link></li>
                        <li><Link to="/episodes/maniac">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)